.loginSettings {
  background: #ffffff;
  margin-top: 29px;
  border-radius: 5px;
}

.loginSettings p {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #000000;
  padding: 20px;
  margin-bottom: 0px;
}

.loginSettingsForm {
  border-top: 2px solid #efefef;
  background: #ffffff;
  padding: 20px;
}

.loginWidth {
  width: 100%;
  max-width: 419px;
}

.loginButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border-top: 2px solid #efefef;
  padding: 20px;
}

.loginSettingsForm h5 {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #ec0000;
}

.loginSettingsForm h2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  color: #373a3c;
  text-transform: uppercase;
}

.loginSettingsForm p {
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  color: #d70303;
}

.loginSettingsForm h4 {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  color: #4a4a4a;
  margin-bottom: 15px;
}

.errorMessage {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  border: 1px solid #f6f6f6 !important;
  background: #B1E410 !important;
  border-radius: 5px;
  color: #010101 !important;
  padding: 15px;
}

.continueBtn {
  background: linear-gradient(169.09deg, #f40000 -9.18%, #7d0e0e 337.8%);
  font-size: 15px;
  font-weight: 700;
  height: 36px;
  padding: 0 15px;
  text-align: center;
  border-radius: 50px;
  border: none;
  color: white;
  margin-bottom: 10px;
}

@media only screen and (max-width: 500px) {
  .loginSettingsForm {
    padding: 20px 33px 20px 18px;
  }
}