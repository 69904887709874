.commentBox {
  margin-top: 29px;
}
.commentInfo {
  background: #ffffff;
  padding: 15px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  justify-content: space-between;
}

.commentText h6 {
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.10000000149011612px;
  color: #000000;
  margin-right: 10px;
  line-break: anywhere;
}
.commentText p {
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.10000000149011612px;
  color: #db0429;
  margin-bottom: 0px;
}
.commentText p strong {
  font-weight: 700;
  color: #000000;
}
.commentImg {
  width: 100%;
  max-width: 106px;
  height: 108px;
  border-radius: 2px;
  box-shadow: 0 1px 1px 0 #00000026;
  margin: 28px 0px 15px;
}
.commentImg img {
  width: 100%;
  max-width: 106px;
  height: 108px;
  border-radius: 5px;
}
.commentInfoEdit {
  background: #ffffff;
  padding: 23px 15px 11px;
  margin-top: 15px;
  border-radius: 5px;
  justify-content: space-between;
}
.commentButton {
  display: flex;
  justify-content: space-between;
  padding-top: 3px;
  align-items: center;
}
.commentButton svg {
  width: 35.4px;
  height: 35.4px;
  margin-left: 12px;
}
.commentButton button {
  border: 1.6px solid #4c4c4c !important;
  text-transform: uppercase;
  font-weight: 600;
}
.commentButton .rightButton button {
  background: #d80000 !important;
  border: 0.8px solid #d40101 !important;
  color: #ffffff;
}
.commentButton .rightButton button:hover {
  color: #ffffff !important;
}
.commentButton .rightButton button:active {
  color: #ffffff !important;
}
.commentInfoDelete {
  background: #bae15c;
  padding: 0px 15px;
  margin-top: 15px;
  border-radius: 5px;
  justify-content: space-between;
}
.commentInfoDelete p {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: #000000;
  margin-bottom: 0px;
}

@media only screen and (max-width: 410px) {
  .commentInfoDelete p {
    font-size: 12px;
  }

  .commentButton button {
    font-size: 15px;
    padding: 0px 13px;
    height: 46px !important;
  }
}

.showImages {
  display: flex;
  flex-wrap: wrap;
}
.showImages .showImageBox {
  width: 106px;
  height: 106px;
  margin-right: 10px;
  margin-top: 10px;
  position: relative;
  background: #000;
  border-radius: 5px;
}
.showImages .showImageBox img {
  width: 106px;
  height: 106px;
  object-fit: cover;
}
.showImages .showImageBox .imageCrossIcon {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 0%;
  right: 0%;
  background: linear-gradient(156.2deg, #ff0000 35.43%, #b80218 109.87%);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageCrossIcon svg {
  width: 10px;
  height: 10px;
  fill: white !important;
}

.vidioUpload .showImageBox {
  margin-bottom: 20px;
  position: relative;
  background: #000;
  border-radius: 5px;
  margin-top: 10px;
}
.vidioUpload .showImageBox video {
  width: 100% !important;
  height: 100% !important;
  border-radius: 10px;
}

.vidioUpload .showImageBox .imageCrossIcon {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 0%;
  right: 0%;
  background: linear-gradient(156.2deg, #ff0000 35.43%, #b80218 109.87%);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reactPlayer {
  width: 100% !important;
}
.reactPlayerAdd {
  width: 300px !important;
  height: 200px !important;
}

.commentInfo:hover {
  background-color: #f5f5f5;
  /* background-color: hsl(0, 0%, 97%); */
  transition: 0.1s ease;
}
@media only screen and (max-width: 420px) {
  .reactPlayer {
    width: 100% !important;
    height: 200px !important;
  }
  .reactPlayerAdd {
    width: 100% !important;
    height: 100% !important;
  }
  .responseIcon {
    margin-left: 5px;
  }
}
