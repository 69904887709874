.moduleTopIcon {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 8px;
}
.moduleTopIcon span svg {
  width: 50px;
  height: 50px;
  fill: white;
}
.conformHedingInner h2 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  margin-bottom: 20px;
}
.conformHedingInner h2 span {
  font-weight: 500;
}
.conformHedingInnerTow h2 {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}
.conformBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.conformBtn .conformSubBtn {
  background: linear-gradient(169.09deg, #f40000 -9.18%, #7d0e0e 337.8%);
  font-size: 15px;
  font-weight: 700;
  line-height: 18.29px;
  text-align: center;
  border: none;
  padding: 0px 25px;
  border-radius: 50px;
  color: white;
  height: 40px;
  margin-bottom: 10px;
  margin-top: 30px;
}

.conformBtn .conformCancelBtn {
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  border: none;
  color: white;
  background-color: transparent;
  margin-bottom: 10px;
  margin-top: 10px;
}

.reportDivImgSignIn {
  text-align: center;
  padding: 5px 40px 40px 40px;
}

.reportDivImgSignIn h5 {
  font-size: 25.08px;
  font-weight: 700;
  letter-spacing: 0.3564000129699707px;
  text-align: center;
  color: #ffffff;
  margin-top: 12px;
  text-transform: uppercase;
}

.reportDivImgSignIn p {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  margin-top: 10px;
}

.conformBtnExitNew {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.conformBtnExitNew .conformBtnNew {
  background: linear-gradient(169.09deg, #f40000 -9.18%, #7d0e0e 337.8%);
  font-size: 15px;
  font-weight: 700;
  line-height: 18.29px;
  text-align: center;
  border: none;
  padding: 0px 25px;
  border-radius: 50px;
  color: white;
  height: 40px;
  margin-bottom: 10px;
  width: 60%;
  margin-top: 5px;
}

.conformBtnExitNew .cancelBtn {
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  border: none;
  color: white;
  background-color: transparent;
  margin-bottom: 10px;
  margin-top: 10px;
  text-transform: uppercase;
}

.signinPopupImg img {
  width: 49px;
  height: 57px;
}

.reportDivImg {
  text-align: center;
  padding: 10px 39px 30px 39px;
}

.reportDivImg h5 {
  font-size: 25.08px;
  font-weight: 700;
  letter-spacing: 0.3564000129699707px;
  text-align: center;
  color: #ffffff;
  margin-top: 10px;
  text-transform: uppercase;
}

.reportDivImg p {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

.conformBtnExit {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.conformBtnExit .conformBtn {
  background: linear-gradient(169.09deg, #f40000 -9.18%, #7d0e0e 337.8%);
  font-size: 15px;
  font-weight: 700;
  line-height: 18.29px;
  text-align: center;
  border: none;
  padding: 0px 25px;
  border-radius: 50px;
  color: white;
  height: 40px;
  margin-bottom: 10px;
  width: 65%;
}

.conformBtnExit .cancelBtn {
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  border: none;
  color: white;
  background-color: transparent;
  margin-bottom: 10px;
  margin-top: 10px;
  text-transform: uppercase;
}

.reportImage img {
  width: 49px;
  height: 57px;
}
