.main {
  min-height: calc(100vh - 189px);
  overflow-x: hidden;
  overflow-y: hidden;
  background: #efefef;
  padding-top: 82px;
}

.container {
  max-width: 980px !important;
}

.topSpace {
  padding: 70px 0;
}

.formSection label {
  font-size: 13px;
  margin-bottom: 0;
  color: #000000;
  font-weight: 500;
}

.formSection input {
  background: transparent;
  border: none;
  color: rgba(0, 0, 0, 0.9);
  font-size: 20px;
  height: auto;
  line-height: auto;
  margin-top: -3px;
  padding: 0;
}

.formSection input::placeholder {
  font-size: 15px;
  color: #999999 !important;
}

.formSection input:focus {
  box-shadow: unset !important;
  border: unset !important;
}

.formSection .formGroup {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 5px 10px;
  height: 65px;
}

/* phone input css */
.phoneInputSet .form-control {
  color: #000000 !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 16.94px !important;
  height: 20px !important;
  box-shadow: unset !important;
  border: unset !important;
  background: #ffffff !important;
  width: 100% !important;
}

.phoneInputSet .form-control::placeholder {
  color: #999999 !important;
}

.phoneInputSet .react-tel-input .flag-dropdown {
  border: none !important;
  background: #ffffff !important;
}

.errorBox {
  margin-bottom: 10px;
  margin-top: -10px;
}

.modalDialog .modal-dialog {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  min-height: calc(100vh - 20px) !important;
}

.modalDialog .modal-content {
  width: 100% !important;
}

.spaceTopSet {
  margin: 20px 0px;
}

/* sidebar css */
.sideTopBox {
  background: #ffffff;
  padding: 15px;
  border-radius: 5px;
}

.sideTopBox h6 {
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  color: #000000;
  margin-bottom: 0px;
  word-break: break-word;
}

.sideTopBox span {
  color: #db010d;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
}

.sideHeading {
  font-size: 9px;
  font-weight: 400;
  line-height: 10.74px;
  letter-spacing: 3px;
  color: #040404;
  margin-top: 11px;
  margin-left: 15px;
  text-transform: uppercase;
}

.sideBar a {
  text-decoration: none;
}

.displayOption {
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 3px;
}

.displayOption:hover {
  background-color: hsl(0, 0%, 97%);
  transition: 0.1s ease;
}

a.userActive.active .displayOption {
  background: #b4dd19;
}

a.userActives.active .displayOption {
  background: #b4dd19;
}

.userActives {
  text-decoration: none !important;
}

.displayOption p {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.71px;
  color: #040404;
  margin-bottom: 0px;
}

.displayOption span {
  color: #ec0000;
  font-size: 11px;
  font-weight: 700;
  line-height: 13.2px;
}

.displayOption span svg {
  color: #000000;
  width: 14px;
  height: 14px;
}

.rightTopPart {
  background: #ffffff;
  padding: 19px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 73px;
  border-radius: 5px;
  position: relative;
}

.rightTopPart h2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  color: #373a3c;
  margin-bottom: 0px;
  text-transform: uppercase;
}

.rightTopPart span {
  font-size: 20px;
  font-weight: 500;
  color: #ed0006;
}

.rightTopPart .dropdown {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: flex-end;
  padding: 0;
  position: relative;
}

.rightTopPart .dropdown button#dropdown-basic {
  background: none;
  border: none;
  color: black;
}

.rightTopPart .dropdown button#dropdown-basic::after {
  content: none;
}

.rightTopPart .dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 0.5rem;
  background-color: #e63434;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  color: #212529;
}

.rightTopPart .dropdown-menu.show {
  display: block;
}

.rightTopPart img {
  height: 39px;
  min-width: 39px;
  width: 39px;
  border-radius: 50%;
}

.searchBox {
  position: relative;
}

.searchBox svg {
  position: absolute;
  top: 4px;
  right: 8px;
  width: 22.35px;
  height: 30.35px;
  color: #333 !important;
}

.searchBox .searchInput {
  border-radius: 150px;
  width: 100%;
  color: #4c4c4c;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: -0.5px;
  background: #efefef;
  padding-right: 34px;
  border: none !important;
}

.searchBox .searchInput:focus {
  border-color: #efefef !important;
  box-shadow: unset !important;
  background: #efefef;
}

.searchBox .searchInput::placeholder {
  color: #999999 !important;
  font-size: 15px;
  font-weight: 400;
}

@media only screen and (min-width: 992px) {
  .rightPart {
    width: 100% !important;
    max-width: 590px !important;
  }

  .centerSet {
    width: 100% !important;
    max-width: 590px !important;
    margin: 0px auto;
  }

  .fullWidth {
    width: 100% !important;
    max-width: 900px !important;
  }
}

@media only screen and (max-width: 767px) {
  .rightPart {
    margin-top: 30px !important;
  }
}

@media only screen and (max-width: 400px) {
  .rightTopPart {
    padding: 19px 5px;
  }

  .searchInput {
    max-width: 152px;
  }

  .rightTopPart h2 {
    font-size: 15px;
    margin-left: 10px;
  }
}

.dropdown-container {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 160px;
  width: 100%;
}

.dropdown {
  flex-grow: 1;
  padding-right: 2rem;
  appearance: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  border: unset;
  background: transparent !important;
  color: red;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown:focus {
  box-shadow: unset !important;
}

.dropdown-icon {
  position: absolute;
  right: 1rem;
  pointer-events: none;
  color: red;
}

/* notification switch css */
.toggle_switch .form-check-input:focus {
  box-shadow: unset !important;
  border: 1px solid #ccc !important;
}

.toggle_switch .form-check-input {
  height: 28px !important;
  width: 45px !important;
}

.toggle_switch .form-check-input:checked {
  background-color: #d80000;
  border-color: #d80000;
  cursor: pointer;
}

/* radio button css in notification unit select*/
.switch-field {
  display: flex;
}

.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.switch-field label {
  background-color: #fff !important;
  color: #000;
  font-size: 14px;
  line-height: 1;
  text-align: center;
  padding: 10px 16px;
  border: 1px solid #cccccc !important;
  transition: all 0.1s ease-in-out;
}

.switch-field label:hover {
  cursor: pointer;
}

.switch-field input:checked+label {
  background-color: #d80000 !important;
  box-shadow: none;
  border: 1px solid #cccccc !important;
  color: #fff;
}

.switch-field label:first-of-type {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.switch-field label:last-of-type {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.switch-field .form-check {
  padding: 0px !important;
}

/* ----radio button-update--alert---- */

.switch-fieldAlert {
  display: flex;
}

.switch-fieldAlert input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.switch-fieldAlert label {
  background-color: #d80000 !important;
  color: white;
  font-size: 14px;
  line-height: 1;
  text-align: center;
  padding: 10px 16px;
  border: 1px solid #d80000 !important;
  transition: all 0.1s ease-in-out;
}

.switch-fieldAlert label:hover {
  cursor: pointer;
}

.switch-fieldAlert input:checked+label {
  background-color: red;
  box-shadow: none;
  border: 1px solid #d80000 !important;
}

.switch-fieldAlert .form-check {
  padding: 0px !important;
}

.switch-fieldAlert .form-check:first-child .form-check-label {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  background-color: red;
}

.switch-fieldAlert .form-check:last-child .form-check-label {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

/* ------- */

@media only screen and (min-width: 411px) and (max-width: 465px) {
  .switch-field label {
    padding: 10px 5px;
  }

  .switch-fieldAlert label {
    padding: 10px 5px;
  }
}

@media only screen and (max-width: 410px) {
  .switch-field label {
    margin-top: 10px;
  }

  .switch-fieldAlert label {
    margin-top: 10px;
  }
}

.formCls label {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #373a3c;
}

.formCls input {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  border: 1px solid #f6f6f6 !important;
  background: #f6f6f6 !important;
  border-radius: 5px;
  height: 38px;
  color: #373a3c;
}

.formCls input::placeholder {
  color: #999999 !important;
}

.formCls input.form-control:focus {
  border: 1px solid #f6f6f6 !important;
  box-shadow: unset !important;
}

.formCls textarea.form-control {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  border: 1px solid #f6f6f6;
  background: #f6f6f6 !important;
  border-radius: 5px;
}

.formCls textarea.form-control::placeholder {
  color: #999999 !important;
}

.formCls textarea.form-control:focus {
  border: 1px solid #f6f6f6;
  box-shadow: unset !important;
}

.errorMsg {
  font-size: 14px;
  font-weight: 700;
  color: #d70303;
}

.inputSelect::-webkit-inner-spin-button,
.inputSelect::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.inputSelect {
  -moz-appearance: textfield;
}

.slider-container {
  position: relative;
}

/* mui slider css */
.SliderSetMui {
  padding: 10px 30px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.SliderSetMuif {
  padding: 10px 30px;
  margin-bottom: 00px;
  display: flex;
  align-items: center;
}

.SliderSetMuif p {
  font-size: 19px !important;
}

.custom-slider .MuiSlider-valueLabel {
  top: calc(100% + 30px) !important;
  background: transparent !important;
  color: #000000 !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}

.custom-slider {
  color: red !important;
}

.custom-slider .MuiSlider-thumb {
  background-color: #ffffff !important;
  width: 25px !important;
  height: 25px !important;
  transition: unset !important;
}

.custom-slider .MuiSlider-track {
  background-color: red !important;
}

.custom-slider .MuiSlider-rail {
  background-color: #b5b5b6 !important;
}

.custom-slider .MuiSlider-thumb:hover {
  box-shadow: unset !important;
}

.custom-slider .MuiSlider-thumb:focus-visible {
  box-shadow: unset !important;
  transition: unset !important;
}

.sliderText {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
  padding: 10px 20px 0px;
}

.sliderText p {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  color: #000000;
}

.iconDisplay {
  display: flex;
}

.setIcon {
  width: 36px;
  height: 38px;
  background: #ececec;
  padding: 4px 10px;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.setIcon svg {
  width: 16px;
  height: 18px;
}

/* facebook social login css */
.SocialLoginBtn button.kep-login-facebook.metro {
  background: unset !important;
  border: unset !important;
  padding: 0px !important;
}
.SocialLoginBtn span{
  opacity: initial !important;
}

.tooltip {
  opacity: 10 !important;
}

.tooltipSetCrime .tooltip-inner {
  min-width: 980px !important;
  width: auto !important;
  text-align: left !important;
  position: relative !important;
  left: 0 !important;
  background: #ffffff !important;
  border: 1px solid #dcdcdc !important;
  box-shadow: 0 25px 40px rgb(0 0 0 / 3%) !important;
  padding: 20px 9px !important;
  overflow-y: scroll;
  height: 530px;
  overflow-x: hidden;
}

.tooltipSetCrime .tooltip-arrow::before {
  display: none;
}

.tooltipSetCrime .tooltip-inner::-webkit-scrollbar {
  width: 6px;
  border-radius: 50px;
}

.tooltipSetCrime .tooltip-inner::-webkit-scrollbar-track {
  background: transparent;
}

.tooltipSetCrime .tooltip-inner::-webkit-scrollbar-thumb {
  background: #d6d6d6;
  border-radius: 50px;
}

@media (max-width: 1200px) {
  .tooltipSetCrime .tooltip-inner {
    height: 500px;
    min-width: 750px !important;
    left: -100px !important;
  }

  .tooltipSetCrime .tooltip-arrow {
    left: 45px !important;
  }
}

@media (max-width: 1024px) {
  .tooltipSetCrime .tooltip-inner {
    height: 500px;
    min-width: 750px !important;
  }

  .tooltipSetCrime .tooltip-arrow {
    left: 45px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .tooltipSetCrime .tooltip-inner {
    min-width: 500px !important;
    left: 0px !important;
  }

  .tooltipSetCrime .tooltip-arrow {
    left: 88px !important;
  }
}

.tooltipSetCrime::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 71%;
  margin-left: -5px;
  border-width: 20px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}

@media (min-width: 535px) and (max-width: 767px) {
  .tooltipSetCrime .tooltip-inner {
    min-width: 480px !important;
  }

  .tooltipSetCrime .tooltip-arrow {
    left: 102px !important;
  }
}

@media (min-width: 420px) and (max-width: 534px) {
  .tooltipSetCrime .tooltip-inner {
    min-width: 396px !important;
  }

  .tooltipSetCrime .tooltip-arrow {
    left: 62px !important;
  }
}

@media (min-width: 370px) and (max-width: 419px) {
  .tooltipSetCrime .tooltip-inner {
    min-width: 363px !important;
    padding: 9px 9px !important;
  }

  .tooltipSetCrime .tooltip-arrow {
    left: 40px !important;
  }
}

@media (min-width: 320px) and (max-width: 369px) {
  .tooltipSetCrime .tooltip-inner {
    min-width: 300px !important;
    padding: 9px 9px !important;
  }

  .tooltipSetCrime .tooltip-arrow {
    left: 40px !important;
  }
}

.modalCls .modal-header {
  display: block;
}

.sliderDate {
  display: block;
  text-align: center;
}

.sliderDate h6 {
  font-size: 13px;
  letter-spacing: 3px;
  margin-top: 7px !important;
  margin-bottom: -12px;
}

.sliderDate strong {
  font-size: 20px;
}

.SliderSetMui p {
  font-size: 19px !important;
}

.genderRadioButoon {
  justify-content: center;
  margin-bottom: 20px;
}

.genderRadioButoon input:checked+label {
  background-color: red !important;
  box-shadow: none;
  border: 1px solid red !important;
  color: #ffffff;
}

.genderRadioButoon label:first-of-type {
  border-radius: 150px;
}

.genderRadioButoon label:last-of-type {
  border-radius: 150px;
}

.genderRadioButoon label {
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  width: 100px;
}

/* HashtagTextArea.css */
.hidden-textarea {
  position: absolute;
  opacity: 0;
  z-index: 1;
  height: auto;
  resize: none;
}

.highlighted-text {
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: white;
  min-height: 136px;
}

/* HashtagTextArea.css */
.editable-div {
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  min-height: 136px;
  outline: none;
}

.editable-div:empty:before {
  color: #6c757d;
}

.hashtag {
  color: red;
}

/* -------dashboardInfo----- */
.dashboardInfo {
  background: #ffffff;
  padding: 15px;
  margin-top: 29px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 28px;
}

.dashboardImg {
  width: 100%;
  max-width: 130px;
  border-radius: 100%;
  margin-right: 15px;
}

.dashboardImg img {
  width: 100%;
  max-width: 130px;
  object-fit: cover;
  border-radius: 100%;
}

.dashboardText p {
  color: #e90202;
  font-size: 17px;
  font-weight: 500;
  line-height: 20.29px;
  margin-bottom: 3px;
}

.dashboardText h3 {
  color: #000000;
  font-size: 24px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: -0.20000000298023224px;
  margin-bottom: 12px;
}

.dashboardText h5 {
  color: #e90202;
  font-size: 17px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
}

.dashboardText h5 strong {
  color: #e90202;
  font-size: 17px;
  font-weight: 700;
  line-height: 18px;
}

.dashboardText h6 {
  color: #d70000;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

/* ------dashboardBoxes--------- */
.dashboardBoxes {
  background: #ffffff;
  padding: 20px 20px 0px 20px;
  border: 1px solid #dcdcdc;
  border-top: unset;
}

.dashboardBox {
  border: 1px solid #dcdcdc;
  background: #fafafa;
  padding: 10px 4px;
  border-radius: 5px;
  margin-bottom: 20px;
  height: 90px;
}

.dashboardBox:hover {
  background: #db010d;
  color: white !important;
}

.dashboardBox p:hover {
  color: white !important;
}

.dashboardBox h1:hover {
  color: white !important;
}

.dashboardBox h1 {
  font-size: 30px;
  font-weight: 400;
  letter-spacing: -0.2142857164144516px;
  text-align: center;
}

.dashboardBox p {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.10000000149011612px;
  text-align: center;
  margin-bottom: 0px;
  text-transform: uppercase;
}

@media only screen and (max-width: 400px) {
  .dashboardBox h1 {
    font-size: 20px;
  }

  .dashboardBox p {
    font-size: 11px;
  }

  .dashboardImg {
    max-width: 90px;
    margin-right: 10px;
  }

  .dashboardImg img {
    max-width: 90px;
  }

  .dashboardText p {
    font-size: 14px;
    margin-bottom: 0px;
  }

  .dashboardText h3 {
    font-size: 18px;
    line-height: 21px;
  }

  .dashboardText h5 {
    font-size: 15px;
  }

  .dashboardText h5 strong {
    font-size: 15px;
  }

  .dashboardText h6 {
    font-size: 10px;
    line-height: 14px;
  }
}

/* -----networks--sidebar----- */
.sidebarNetworks .sideTopBox {
  background: #707070 !important;
}

.sidebarNetworks .sideTopBox h6 {
  color: white;
}

.sidebarNetworks .sideTopBox span {
  color: #ffffff;
}

/* ----------reportIncident----- */
.reportIncident {
  background: #ffffff;
  margin-top: 29px;
  margin-bottom: 20px;
  padding: 20px 0px;
  border-radius: 5px;
}

.reportIncident h3 {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  color: #000000;
  padding-left: 20px;
  margin-bottom: 16px;
  text-transform: uppercase;
}

.reportIncident h3 strong {
  font-weight: 900;
}

.reportSelect {
  padding: 20px 20px 20px 0px;
  border-bottom: 1px solid #dadada;
  border-top: 1px solid #dadada;
  margin-left: 20px;
  position: relative;
}

.reportSelect svg {
  color: white;
  width: 22px;
  height: 18px;
}

.reportSelect button {
  background: linear-gradient(90deg, #f40000 0%, #b70f0f 100%);
  border: unset;
  padding: 10px;
  width: 100%;
  color: #ffffff;
}

.reportManage {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reportManage p {
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  border-radius: 5px;
  letter-spacing: -0.5px;
}

/* -------networks--newreport--date */
.filterSection {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 0px;
}

.filterSection h5 {
  color: #d30000;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 10px;
}

.filterSection .filterInputGroup {
  background: #4c4c4c;
  color: #ffffff;
  height: 38px;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.datepicker input {
  background: #f6f6f6;
  height: 38px;
  border: unset;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  padding-left: 11px;
  width: 100%;
}

.datepicker input:focus-visible {
  outline: unset !important;
}

.reportIncidentOverlay .filterSection .filterInputGroup {
  background: #000000;
}

@media only screen and (min-width: 768px) and (max-width: 800px) {
  .datepicker input {
    width: 100%;
    max-width: 136px;
  }
}

@media only screen and (min-width: 420px) and (max-width: 533px) {
  .datepicker input {
    width: 100%;
    max-width: 127px;
  }

  .reportImg {
    max-width: 100px;
    margin-right: 10px;
  }

  .reportImg img {
    max-width: 100px;
  }

  .reportText p {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .reportText h3 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 419px) {
  .filterSection {
    display: block;
  }

  .reportInfo {
    flex-direction: column;
  }

  .reportText p {
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 5px;
    text-align: center;
  }

  .reportText h3 {
    font-size: 19px;
    text-align: center;
  }
}

/* -networks new report loction--- */
.location {
  padding: 0px 20px 0px;
}

.location h5 {
  color: #d30000;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.location .inputGroup {
  background: #4c4c4c;
  color: #ffffff;
  height: 38px;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.location input {
  background: #f6f6f6;
  height: 38px;
  border: unset;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  padding-left: 11px;
  width: 100%;
}

.location input:focus {
  box-shadow: unset !important;
  background: #f6f6f6;
}

.location input::placeholder {
  color: #4c4c4c;
}

.location p {
  color: #4c4c4c;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.2px;
  margin-bottom: 0px;
}

.reportIncidentOverlay .location .inputGroup {
  background: black;
}

.googleMapSet {
  margin: 15px -10px 10px;
}

.showCenter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #dadada;
  margin-left: 20px;
}

.showCenter h6 {
  font-size: 15px;
  font-weight: 700;
  line-height: 19.2px;
  color: #4c4c4c;
  margin-bottom: 0px;
}

.showCenter p {
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: 400;
  line-height: 19.2px;
  color: #4c4c4c;
}

.showCenter p strong {
  font-size: 15px;
  font-weight: 700;
  line-height: 19.2px;
  margin-left: 10px;
}

.showCenter h5 strong {
  font-weight: 700 !important;
}

.reportIncidentOverlay .showCenter {
  padding: 15px 20px 15px 20px;
  border-bottom: 1px solid #dadada;
  margin-left: -10px;
  margin-right: -10px;
}

.reportIncidentOverlay .showCenter span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.reportIncidentOverlay .showCenter span svg {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

/* --Incident--- */
.hashTag {
  color: #6b6b6b !important;
  margin-top: 5px;
}

/* image upload css */
.uploadSection label {
  background: #000000;
  color: #ffffff;
  width: 100%;
  height: 49px;
  border-radius: 8px;
  text-align: center;
  padding: 15px 0px;
  font-size: 13px;
  font-weight: 700;
  margin: 15px 0px;
}

.uploadSection label svg {
  width: 27px;
  height: 21px;
  margin-right: 2px;
  margin-bottom: 4px;
}

.showImages {
  display: flex;
  margin-top: 5px;
  flex-wrap: wrap;
}

.showImages .showImageBox {
  width: 60px;
  height: 60px;
  margin-right: 10px;
  margin-top: 10px;
  position: relative;
}

.showImages .showImageBox img {
  width: 60px;
  height: 60px;
  object-fit: cover;
}

.showImages .showImageBox video {
  width: 60px;
  height: 60px;
  object-fit: cover;
}

.showImages .showImageBox svg {
  position: absolute;
  top: 4px;
  right: 4px;
  color: #ffffff;
  cursor: pointer;
}

/*-------- slider div css----------- */
.reportSlider {
  margin-bottom: 10px;
  background-color: white;
}

.reportInfo {
  background: #ffffff;
  padding: 15px 46px;
  display: flex !important;
  align-items: center;
  position: relative;
}

.reportImg {
  width: 100%;
  max-width: 100px;
  border-radius: 100%;
  margin-right: 20px;
}

.reportImg img {
  width: 100%;
  max-width: 100px;
}

.reportText p {
  color: #d81010;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
}

.reportText p strong {
  margin-left: 20px;
}

.reportText h3 {
  font-size: 23px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: -0.5px;
  color: #4c4c4c;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.reportText h6 {
  color: #d81010;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
}

.reportSlider button {
  background: #cdcdcd;
  color: #d80000;
}

.arrrowLeft {
  position: absolute;
  color: red;
  z-index: 1;
  top: 0%;
  left: 0px;
  background: #cdcdcd;
  height: 100%;
  width: 40px;
}

.arrrowLeft svg {
  position: absolute;
  top: 40%;
  left: -11px;
  width: 60px;
  height: 29px;
}

.arrrowRight {
  position: absolute;
  color: red;
  z-index: 1;
  top: 0%;
  right: 0px;

  background: #cdcdcd;
  height: 100%;
  width: 40px;
}

.arrrowRight svg {
  position: absolute;
  top: 40%;
  right: -11px;
  width: 60px;
  height: 29px;
}

/* silder image right icon css */
.publicSliderTop .reportImg {
  position: relative;
}

.sliderImageIcon {
  position: absolute;
  bottom: 0%;
  right: 0%;
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border: 2px solid #ffffff;
  background: linear-gradient(169.09deg, #f40000 -9.18%, #7d0e0e 337.8%);
}

.sliderImageIcon svg {
  fill: white;
  width: 18px;
  height: 22px;
}

.reportInfoRight {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.reportInfoRight h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  color: #d90303;
  margin-bottom: 0px;
  margin-right: 5px;
}

.reportInfoRight span {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.reportInfoRight span p {
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  margin-bottom: 0px;
}

.publicPvtSlider .reportInfoRight svg {
  width: 38px;
  height: 28px;
  margin-right: 10px;
}

.publicPvtSlider .arrrowLeft svg {
  top: 25% !important;
}

.publicPvtSlider .arrrowRight svg {
  top: 25%;
}

/* -----netqorks--btn-bottomReport----- */
.bottomReport {
  background: #ffffff;
  padding: 20px;
  border-radius: 5px;
}

.bottomReport h2 {
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  color: #373a3c;
  text-align: center;
}

.bottomReport h2 strong {
  color: #d80000;
}

.bottomReport .reportButton {
  display: flex;
  margin-top: 25px;
}

.bottomReport .reportButton .leftButton {
  color: #4c4c4c;
  border: 2px solid #4c4c4c;
  height: 52px;
  text-transform: uppercase;
  border-radius: 4px;
  padding: 2px 26px;
  font-size: 17px;
  font-weight: 700;
  background: #ffffff;
}

.bottomReport .reportButton .rightButton {
  border: 1px solid #d40101;
  background: #d80000;
  color: #ffffff;
  height: 52px;
  text-transform: uppercase;
  border-radius: 4px;
  margin-left: 20px;
  width: 100%;
  font-size: 17px;
  font-weight: 700;
}

/* ----error message--- */
.errorMessageShow {
  background: #d1fa8a;
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-top: 10px;
  display: none;
}

.errorMessageShow p {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #000000;
}

/* --------------networks--reports--------- */
.reportIncidentInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.reportIncidentInner span {
  margin-bottom: 16px;
  padding-right: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.2px;
  color: #817a7a;
  padding-left: 20px;
}

/* -----report-details */

/* image */
.imageRowMain {
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.imageRowMainDiv {
  width: 100%;
  height: 100%;
  max-width: 200px;
}

.imageRowMainDiv img {
  border-radius: 5px;
  width: 100%;
  height: 100%;
  max-width: 200px;
}

@media (max-width: 1200px) {
  .imageRowMain {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media (max-width: 991px) {
  .imageRowMain {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (max-width: 768px) {
  .imageRowMain {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media (max-width: 575px) {
  .imageRowMain {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (max-width: 480px) {
  .imageRowMain {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* ---drop down */

ul.p-multiselect-items {
  padding-left: 0px;
}

.p-multiselect:focus {
  box-shadow: 1px solid white;
}

.p-multiselect:hover {
  border-color: rgb(177, 177, 177);
}

.p-multiselect:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 0rem #a5f3fc;
}

/* ----records Found */
.records_Found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
  background-color: white;
  margin-top: 20px;
  border-radius: 5px;
}

.records_Found h2 {
  font-size: 20px;
  font-weight: 500;
  color: #373a3c;
}

/* ----comment---- */
.commentsMainDiv {
  background-color: white;
  padding: 15px;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
}

.commentsCountMain {
  display: flex;
  justify-content: start;
  align-items: center;
}

.commentsCountIcon svg {
  width: 23px;
  height: 20px;
  margin-right: 10px;
}

.commentsCountRight span {
  display: flex;
  font-size: 17px;
  font-weight: 500;
  color: #303c42;
}

.commentsCountRight span p {
  margin-bottom: 0px;
}

.commentsDescription {
  margin-top: 10px;
}

.commentsDescription span {
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.commentsDescription span h2 {
  font-size: 16px;
  font-weight: 700;
  margin-right: 3px;
  margin-bottom: 0px;
}

.commentsInput {
  display: flex;
  align-items: start;
}

.commentsInputLeft svg {
  width: 35px;
  height: 35px;
  margin-right: 10px;
  cursor: pointer;
}

.commentsInput span {
  width: 35px;
  height: 35px;
  background: linear-gradient(156.2deg, #ff0000 35.43%, #b80218 109.87%);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.commentsInput span svg {
  width: 23px;
  height: 23px;
}

.commentsInput span svg path {
  fill: white;
}

.commentsInputLeft {
  flex: 0 0 auto;
}

.commentsInputCenter {
  flex: 1;
}

.commentsInputCenter textarea {
  width: 98%;
  padding: 6px 10px;
  border: 1px solid #efefef;
  border-radius: 5px;
  background-color: #efefef;
  max-height: 175px;
}

.commentsInputCenter textarea::placeholder {
  font-size: 15px;
  font-weight: 400;
  color: #303c42;
}

.commentsInputCenter textarea:focus {
  border-color: #303c42;
  box-shadow: none;
}

/* ----comments--text-list-------- */

.scrollheight {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 6px;
  overflow-x: hidden;
  max-height: 100%;
  height: calc(100vh - 100px);
}

.commTextDiv {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  overflow-y: auto;
}

.scrollheight::-webkit-scrollbar {
  width: 7px;
}

.scrollheight::-webkit-scrollbar-track {
  border-radius: 50px;
}

.scrollheight::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10px;
}

.commTextDivInner {
  display: flex;
  margin-bottom: 10px;
}

.commTextDiv .imgIcon img {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d8d8d8;
  margin-right: 10px;
}

.commTextDiv .messageIconInner {
  width: calc(100% - 10px);
  max-width: 340px;
  background-color: #e1e1e1;
  border-radius: 0px 10px 10px 10px;
  position: relative;
  padding: 10px;
  margin-right: 10px;
  box-sizing: border-box;
}

.commTextDiv .messageIcon p {
  font-size: 14px;
  font-weight: 500;
  color: #ec0101;
  margin-bottom: 0px;
}

.commTextDiv .messageIconInner .messageIconShape {
  position: absolute;
  left: -17px;
  top: 0;
  height: 0;
  width: 0;
  border-top: 28px solid #e1e1e1;
  border-left: 28px solid transparent;
}

.messageIcon .messageIconInner h2 {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: #212529;
  margin-bottom: 0px;
  word-break: break-word;
}

.messageIcon .messageIconInner img {
  width: 100%;
  object-fit: cover;
}

/* right */

.commTextReply {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.commTextDiv .commTextReply .messageIconInner .messageIconShapeRight {
  position: absolute;
  right: -17px;
  top: 0;
  border-top: 28px solid #f44a4a;
  border-right: 28px solid transparent;
}

.commTextDiv .commTextReply .messageIcon p {
  text-align: end;
  margin-right: 12px;
}

.commTextDiv .commTextReply .messageIconInner {
  background-color: #f44a4a;
  border-radius: 10px 0px 10px 10px;
}

.commTextReply .messageIcon .messageIconInner h2 {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: #faf9f9;
  margin-bottom: 0px;
  word-break: break-word;
}

.commTextDiv .commTextReply .imgIconRight img {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.commTextReply .commTextDivInner {
  display: flex;
  justify-content: start;
  align-items: start;
}

/* ----leftimg */
.sendMessage .commTextDivInner {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.sendMessage .imgIconLeft {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.messageIcon .messageIconInner .chatImage {
  width: 100%;
  object-fit: cover;
  max-height: 180px;
}

.tooltipSet .tooltip-inner {
  min-width: 334px !important;
  width: auto !important;
  text-align: left !important;
  position: relative !important;
  left: 0 !important;
  background: #ffffff !important;
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.7) !important;
  padding: 6px 9px !important;
  overflow: scroll;
  top: -14px;
  overflow-x: hidden;
  overflow-y: hidden;
}

.tooltipSet .tooltip-arrow {
  position: absolute;
  top: -22px !important;
}

.tooltipSet .tooltip-arrow::before {
  position: absolute !important;
  content: "" !important;
  border-width: 0 15px 15px !important;
  border-style: solid !important;
  border-color: transparent transparent #dfdddd transparent !important;
}

.SliderSetMuiTime {
  padding: 10px 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.SliderSetMuiTime p {
  margin-bottom: 0px;
}

@media only screen and (max-width: 419px) {
  .tooltipSet .tooltip-inner {
    top: 0px !important;
  }

  .tooltipSet .tooltip-arrow {
    top: -8px !important;
  }
}

/* new report date picker css */
.customCheckbox .form-check-input {
  width: 18px;
  height: 18px;
  border: 1px solid #ccc;
  border-radius: 4px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: white;
  cursor: pointer;
  position: relative;
  border-radius: unset;
}

.customCheckbox .form-check-input:checked {
  background-color: white !important;
  border-color: gray !important;
  border-radius: unset !important;
}

.customCheckbox .form-check-input:checked::before {
  content: "";
  display: block;
  width: 8px;
  height: 14px;
  border: solid red;
  border-width: 0 3px 3px 0;
  position: absolute;
  top: -2px;
  left: 4px;
  transform: rotate(45deg);
}

.DateSetPicker .react-datepicker__current-month {
  font-size: 20px !important;
  font-weight: 700 !important;
  color: black !important;
}

.DateSetPicker .react-datepicker__current-month:hover {
  color: red !important;
}

.DateSetPicker .react-datepicker__header {
  background: #fff !important;
}

.DateSetPicker button.react-datepicker__navigation.react-datepicker__navigation--previous {
  border: 3px solid black;
  border-radius: 50%;
  margin-left: 19px;
  margin-top: 7px;
  width: 30px;
  height: 30px;
}

.DateSetPicker button.react-datepicker__navigation.react-datepicker__navigation--next {
  border: 3px solid black;
  border-radius: 50%;
  margin-right: 19px;
  margin-top: 7px;
  width: 30px;
  height: 30px;
}

.DateSetPicker .react-datepicker__navigation-icon::before {
  border-color: black !important;
  left: -5px;
  top: 7px;
}

.DateSetPicker .react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #cdc8c8 !important;
  padding: 15px 0px 4px;
  width: 278px;
}

.DateSetPicker .react-datepicker__month-container {
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.7) !important;
}

.DateSetPicker .react-datepicker__day {
  margin: 6px !important;
}

.DateSetPicker .react-datepicker {
  width: 280px;
  border: 1px solid #e5e5e5 !important;
}

.DateSetPicker .react-datepicker__day-name {
  margin: 6px !important;
}

.DateSetPicker .react-datepicker__day--selected {
  border-radius: 50%;
  background-color: red;
  width: 28px;
  font-weight: 600;
  height: 28px;
}

.DateSetPicker .react-datepicker__day:hover {
  border-radius: 50%;
  background-color: rgb(223 120 123);
  font-weight: 600;
  color: #fff;
}

.DateSetPicker .react-datepicker__day--today {
  font-weight: bold;
  color: #fff !important;
  background: #414040;
  border-radius: 3px;
}

.commentsCountMain .commentsMainDiv .react-datepicker__day-names {
  margin-bottom: -7px !important;
  margin-top: 4px !important;
}

.DateSetPicker svg {
  fill: #fff !important;
  columns: #e5e5e5 !important;
}

.mainModule {
  z-index: 1000000 !important;
}

.mainModule .modal-dialog {
  width: 100%;
  max-width: 400px;
  margin: 0px auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000 !important;
}

.mainModule .modal-content {
  flex-shrink: 0;
  border-radius: 10px;
  background: #020202 !important;
  color: white;
  z-index: 1000000 !important;
}

/* ----showSelectedImg--- */
.showSelectedImg {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.showSelectedImgInner {
  position: relative;
  width: 100%;
  max-width: 130px;
}

.showSelectedImgInner img {
  width: 100%;
  max-width: 130px;
  object-fit: cover;
  border-radius: 10px;
  height: 100%;
}

.showSelectedImgInner video {
  object-fit: cover;
  border-radius: 10px;
}

.imageCrossIcon svg {
  position: absolute;
  padding: 4px;
  top: 0;
  right: 0;
  width: 21px;
  height: 21px;
  border-radius: 5px;
  background-color: #f44a4a;
  cursor: pointer;
}

.imageCrossIcon svg path {
  fill: white;
}

/* ---popup--css---- */

.header {
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 12px 12px;
}

.header .alertCross svg {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.header .alertCross svg path {
  fill: white !important;
}

.hadingMainDiv {
  padding: 0px 30px 30px;
}

/* ------load--more---- */
.loadMoreSection {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 20px;
}

.loadMoreBtn {
  padding: 5px 20px;
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  background-color: #e90202;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.formCls .form-select {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  border: 1px solid #f6f6f6 !important;
  background: #f6f6f6 !important;
  border-radius: 5px;
  height: 38px;
  color: #373a3c !important;
}

.formCls .form-select::placeholder {
  color: #999999 !important;
}

.formCls .form-select:focus {
  border: 1px solid #f6f6f6 !important;
  box-shadow: unset !important;
}

/* Add this CSS to your styles.css or relevant stylesheet */
.custom-select {
  position: relative;
  z-index: 1;
}

.custom-select::after {
  content: "\25BC";
  position: absolute;
  top: 50%;
  right: 10px;
  pointer-events: none;
  transform: translateY(-50%);
  z-index: 2;
  color: #333;
}

.formCls {
  position: relative;
  z-index: 0;
}

.custom-select {
  flex-grow: 1 !important;
  padding-right: 2rem !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  border: unset !important;
  background: transparent !important;
  color: black !important;
  width: 100% !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  z-index: 999999999999999;
}

.center-marker {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  background-size: contain !important;
  pointer-events: none !important;
}

.center-marker img {
  width: 100% !important;
  max-width: 50px !important;
  min-width: 50px !important;
  height: 50px !important;
}

@supports (-webkit-appearance: none) {
  .center-marker {
    background-size: contain !important;
    left: 50% !important;
    pointer-events: none !important;
    position: absolute !important;
    top: 50% !important;
    transform: translate(-25%, -50%) !important;
    /* height: 100px !important; */
    width: 100% !important;
    max-width: 50px !important;
    min-width: 100px !important;
  }

  .center-marker img {
    width: 100% !important;
    max-width: 50px !important;
    min-width: 50px !important;
    height: 50px !important;
  }
}

.termsModalCls {
  margin-top: 80px !important;
}

.termsModalCls .modal-header {
  background: linear-gradient(90deg, #870000 0, #420000) !important;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  padding: 15px 30px 13px;
}

.termsModalCls .modal-body {
  overflow-y: scroll;
  padding: 30px;
  height: 500px;
}

.termsModalCls .modal-footer {
  justify-content: flex-start !important;
  padding: 3px 30px 3px;
}

.fade.modalCls.termsModalCls.modal.show {
  padding-left: 0px !important;
}

.dropdown-menu.show {
  display: contents !important;
}

/* show-footer-in-bootem */
.app-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}


.main-content {
  flex-grow: 1;
}

.phoneBody {
  display: flex;
  align-items: center;
}

.loginWidth {
  width: 100%;
  max-width: 419px;
  position: relative;
}

.loginWidth .selected-flag {
  background: #f6f6f6;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: #f6f6f6 !important;
}

.loginWidth input.form-control {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px;
  border: 1px solid #f6f6f6 !important;
  background: #f6f6f6 !important;
  border-radius: 5px;
  height: 38px !important;
}

.Image {
  height: 38px;
  border-radius: 5px !important;
  padding: 9px !important;
  background-color: #d70303;
  margin-bottom: 1rem;
  width: 43px;
  color: white;
}

.crossIcon {
  position: absolute;
  right: -33px;
  bottom: 50px;
  color: red;
  width: 28px;
  height: 28px;
  margin-bottom: 1rem;
}

.checkIcon {
  position: absolute;
  right: -33px;
  bottom: 50px;
  color: #28a84b;
  width: 28px;
  height: 28px;
  margin-bottom: 1rem;
}

.LocationInput h5 {
  margin-bottom: 0.5rem;
  font-size: 16px;
  font-weight: 400;
  color: #373a3c;
}

.LocationInput p {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.2px;
  color: #818a91;
  margin-bottom: 1rem !important;
}

.LocationInput span.input-group-text {
  background-color: rgb(215, 3, 3) !important;
}

.LocationInput input.form-control.pac-target-input {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  border: 1px solid #f6f6f6 !important;
  background: #f6f6f6 !important;
  border-radius: 5px;
  height: 38px;
  color: #373a3c;
}

.modal-body h1 {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
}

.fullWidth {
  margin-top: 20px !important;
}

.LocationInput input.form-control.pac-target-input:focus {
  box-shadow: none !important;
}

.mapInfo p {
  font-size: 12.1px;
  font-weight: 700;
  line-height: 16.5px;
  color: #130505;
  margin-bottom: 0px;
}

.mapInfo p:hover {
  color: #d90226 !important;
}

.mapInfo span {
  font-size: 12.1px;
  font-weight: 400;
  line-height: 16.5px;
  color: #d90226;
}

.mapInfo {
  cursor: pointer;
}

button.gm-ui-hover-effect {
  display: none !important;
}

.fullSpace {
  margin-top: 20px !important;
}

.daterangepicker .ranges li.active {
  background-color: red !important;
  color: #fff !important;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: red !important;
  border-color: transparent !important;
  color: #fff !important;
}

/* Ensuring that disabled dates remain inactive css start */
.daterangepicker td.off,
.daterangepicker td.off:hover {
  background-color: #f0f0f0 !important;
  color: #999 !important;
  pointer-events: none;
}

.daterangepicker td.active:not(.off),
.daterangepicker td.active:not(.off):hover {
  background-color: red !important;
  color: #fff !important;
}

.daterangepicker td.end-date.off,
.daterangepicker td.start-date.off {
  background-color: #f0f0f0 !important;
  color: #999 !important;
  border-radius: 0 !important;
}

/*  disble css end */

.daterangepicker .drp-selected {
  display: none !important;
}

button.applyBtn {
  background-color: red !important;
  border-color: red !important;
  color: #fff !important;
}

.daterangepicker td.end-date {
  border-radius: 0 15px 15px 0 !important;
}

.daterangepicker td.start-date {
  border-radius: 15px 0 0 15px !important;
}

.datepickerRange input.form-control:focus {
  border-color: #dcdcdc !important;
  box-shadow: unset !important;
}

.tabsAdd .nav-link.active {
  background: #e5e5e5 !important;
  margin-bottom: 10px;
  color: #000 !important;
  font-weight: 500 !important;
  display: flex !important;
}

.tabsAdd .nav-link svg {
  display: none !important;
}

.tabsAdd .active svg {
  display: block !important;
}

@media (max-width: 991px) {
  .tooltipSetCrime .tooltip-inner {
    overflow: scroll;
    height: 500px;
    overflow-x: hidden;
  }

  .tooltipSetCrime::after {
    display: none;
  }

  .termsModalCls .modal-body {
    height: 400px !important;
  }
}

@media (min-width: 1199px) {
  .multipleCrime {
    left: -140px !important;
  }
}

@media (min-width: 1130px) and (max-width: 1198px) {
  .multipleCrime {
    left: -100px !important;
  }
}

@media (min-width: 1080px) and (max-width: 1129px) {
  .multipleCrime {
    left: -80px !important;
  }
}

.spaceRemove {
  margin-top: 0px !important;
}

.socialButton button {
  border: transparent !important;
}

.profileUserGreen {
  font-size: 14px;
  font-weight: 700;
  color: #2c7e2c;
}

.modalSpace {
  margin-top: 100px;
  height: 400px;
}

.reactEasyCrop_Container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  user-select: none;
  touch-action: none;
  cursor: move;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 268px;
  width: 498px;
}

.seprateLine {
  margin-top: 2px;
}

/* report details video  */
.VideoRowMain {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  flex-direction: column;
  margin-bottom: 20px;
  gap: 10px;
}

.videoContainer {
  overflow: hidden;
  background: #000;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: calc(100%);
  height: 349px;
}

.ReactPlayer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .videoContainer {
    height: 300px !important;
  }
}

@media (max-width: 575px) {
  .videoContainer {
    height: 250px !important;
  }
}

@media (max-width: 425px) {
  .videoContainer {
    height: 225px !important;
  }
}

@media (max-width: 375px) {
  .videoContainer {
    height: 200px !important;
  }
}

.light-text {
  color: #000000;
  font-size: 15px;
  font-weight: 300;
}

.showSelectedImgs {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.showLeft {
  background-color: white !important;
  border: 1px solid white !important;
  text-shadow: white !important;
  color: black !important;
  padding: 10px;
}

.icons li {
  background: none repeat scroll 0 0 black;
  height: 7px;
  width: 7px;
  line-height: 0;
  list-style: none outside none;
  margin-right: 15px;
  margin-top: 3px;
  vertical-align: top;
  border-radius: 50%;
  pointer-events: none;
}

.btn-left {
  left: 0.4em;
}

.btn-right {
  right: 16.4em;
}

.dropbtn {
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.dropbtn svg {
  position: relative;
}

.dropdown {
  position: absolute;
  display: inline-block;
  right: 0.4em;
  overflow: unset;
}

.dropdown-content {
  position: absolute;
  top: 26px;
  background-color: #ffffff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content h1 {
  color: black;
  padding: 7px 11px;
  text-decoration: none;
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.dropdown h1:hover {
  background-color: #f1f1f1;
}

.show {
  display: block;
}

/* modal to see image css */
.mainModuleImg {
  background-color: #000;
}

.mainModuleImg .modal-content {
  background: none !important;
}

.mainModuleImg .modal-content .imageShow {
  margin-top: 150px;
  position: relative;
}

.mainModuleImg .modal-close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: white;
  font-size: 15px;
}

.mainModuleImg .modal-image {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.showSelectedImgInners {
  position: relative;
  height: 100px;
  width: 100px;
}

.showSelectedImgInners img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.imageCrossIcons svg {
  position: absolute;
  padding: 4px;
  top: 0;
  right: 0;
  width: 21px;
  height: 21px;
  background-color: #f44a4a;
  cursor: pointer;
}

.imageCrossIcons svg path {
  fill: white;
}

.iconDisplay img {
  cursor: pointer;
}

.readTick {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 5px;
  font-size: 18px;
  font-weight: 600;
}

.audioMessage {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

/* alert location unit show */
.slider-container {
  margin: 0px 0px 20px 0px;
}

.value-display h3 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 0;
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  padding: 0px;
  color: #000000;
}

.custom-slider .MuiSlider-valueLabel {
  display: none;
}

.customCheckbox .form-check-input:focus {
  border: 1px solid #ccc !important;
  box-shadow: unset !important;
}

.notFound {
  width: 100%;
  background-color: white;
  margin-top: 20px;
  border-radius: 5px;
  text-align: center;
  padding: 15px;
  margin-bottom: 20px;
}

.notFound h3 {
  color: #000;
  font-weight: 400;
  margin-bottom: 13px;
  font-size: 40px;
  line-height: 48px;
}

.notFound p {
  font-size: 80%;
  font-weight: 400;
  margin-bottom: 0px;
}

/* ------ */
.commTextDivNew {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  overflow-y: auto;
}

.commTextDivNew .imgIcon img {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d8d8d8;
  margin-right: 10px;
}

.commTextDivNew .messageIconInner {
  width: calc(100% - 10px);
  max-width: 340px;
  background-color: #e1e1e1;
  border-radius: 0px 10px 10px 10px;
  position: relative;
  padding: 10px;
  margin-right: 10px;
  box-sizing: border-box;
}

.commTextDivNew .messageIcon p {
  font-size: 14px;
  font-weight: 500;
  color: #ec0101;
  margin-bottom: 0px;
}

.commTextDivNew .messageIconInner .messageIconShape {
  position: absolute;
  left: -17px;
  top: 0;
  height: 0;
  width: 0;
  border-top: 28px solid #e1e1e1;
  border-left: 28px solid transparent;
}

.commTextDivNew .commTextReply .messageIconInner .messageIconShapeRight {
  position: absolute;
  right: -18px;
  top: 0;
  border-top: 28px solid #f44a4a;
  border-right: 28px solid transparent;
}

.commTextDivNew .commTextReply .messageIcon p {
  text-align: end;
  margin-right: 12px;
}

.commTextDivNew .commTextReply .messageIconInner {
  background-color: #f44a4a;
  border-radius: 10px 0px 10px 10px;
}

.commTextDivNew .commTextReply .imgIconRight img {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalCls {
  margin-top: 80px !important;
}

.modalCls .modal-content {
  height: 450px;
  width: 100%;
  z-index: 999999;
  overflow: hidden;
}

.modalClscc .modal-header {
  display: block;
}

.modalClscc {
  margin-top: 80px !important;
}

.modalClscc {
  height: 200px;
  width: 100%;
  z-index: 999999;
  overflow: hidden;
}

.dropdown-iconss {
  position: absolute;
  right: 1rem;
  pointer-events: none;
  color: #ffffff !important;
}

.dropdownAdd {
  color: #ffffff !important;
}

/* firbaseToster */

.FirbaseToaster {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
  z-index: 9999999999;
  position: fixed;
  padding-right: 15px;
  margin-top: 15px;
}

.greenIconOnlineMain {
  position: absolute;
  bottom: 10%;
  right: 7%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: #00a703;
  border: 2px solid #f3f5f3;
}

/* Custom position for the close button */
.lg-toolbar .lg-icon {
  margin-top: 90px !important;
}

.editor-container {
  padding: 10px;
  min-height: 118px;
  cursor: text;
  font-size: 16px;
  background: #f6f6f6 !important;
}

.setIconEdit {
  width: 36px;
  height: 38px;
  background: #ececec;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.setIconEdit img {
  width: 16px;
  height: 18px;
}

.FirbaseToaster .toast-header .Notifydiv {
  width: 100%;
  max-width: 50px;
  margin-right: 15px;
}

.tosterNotifyMainDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.FirbaseToaster .toast-header .NotifyPara {
  display: flex;
  flex-direction: column;
}

.FirbaseToaster .toast-header .NotifyPara p {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #000;
  display: -webkit-box;
  overflow: hidden;
}

.FirbaseToaster .toast-header .NotifyPara strong {
  color: black;
  font-size: 15px;
}

.FirbaseToaster .toast-header .btn-close {
  display: none;
}

.NotifyParaCrossIcon {
  cursor: pointer;
  position: absolute;
  right: 25px;
  top: 5px;
  font-size: 20px;
}

.NotifyParaCrossIcon svg {
  width: 14px;
  height: 14px;
}

.NotifyPararr {
  cursor: pointer;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.editor-container .public-DraftEditorPlaceholder-root {
  color: rgb(168, 166, 166);
}

.mainModuleAdd .modal-dialog {
  margin: -52px auto !important;
}

/* ---LightGallery */
.lg-outer .lg-object {
  vertical-align: middle !important;
  width: 50% !important;
  height: 50% !important;
  object-fit: contain;
}

/* In your CSS file */
.phone-input-wrapper {
  position: relative;
}

.phone-input-wrapper .placeholder-text {
  position: absolute;
  top: 50%;
  left: 40px;
  /* Adjust as needed */
  color: #999;
  font-style: italic;
  pointer-events: none;
  /* Prevent interaction with the placeholder */
  transform: translateY(-50%);
}

.phone-input-wrapper .phone-input {
  padding-left: 90px;
  /* Adjust to make space for the placeholder */
}