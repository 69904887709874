.ModalMainBox {
  margin-top: 80px;
}

.ModalBody {
  height: 268px;
  width: 498px;
}

.ModalHeader {
  background-color: #eeeeee;
}

.ModalHeader p {
  font-weight: 400;
  font-size: 20px;
  color: #ff4300;
  margin: 0;
}

.ModalHeader span {
  font-weight: 700;
  font-size: 20px;
  color: #ff4300;
  margin: 0;
}

.ModalFooter {
  background-color: #eeeeee;
}

.ModalFooter button {
  width: 90px;
  height: 34px;
  border-radius: 4px;
  border: 1px solid #ff4300;
  background: #ff4300;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ModalFooter button p {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.1px;
  color: #ffffff;
  margin: 0;
}

@media screen and (min-width: 320px) and (max-width: 575px) {
  .ModalBody {
    height: 268px;
    width: 302px;
  }

  .ModalMainBox {
    width: 320px;
  }
}