.messageBox {
  margin-top: 29px;
  border-radius: 5px;
}

.messageInfo {
  background: #ffffff;
  padding: 15px;
  margin-top: 2px;
  display: flex;
}

.messageInfo:hover {
  background-color: hsl(0, 0%, 97%);
  transition: 0.1s ease;
}

.messageImg {
  width: 100%;
  max-width: 57.62px;
  border-radius: 100%;
  margin-right: 15px !important;
  margin: auto 0px;
  position: relative;
}

.greenIconUser {
  border-radius: 50%;
  height: 16px;
  width: 16px;
  background-color: #00a900;
  border: 2px solid #fff;
  position: absolute;
  left: 45px;
  bottom: 5px;
}

.redIconUser {
  border-radius: 50%;
  height: 16px;
  width: 16px;
  background-color: red;
  border: 2px solid #fff;
  position: absolute;
  left: 45px;
  bottom: 5px;
}

.messageImg img {
  height: 56px;
  min-width: 56px;
  overflow: hidden;
  width: 56px;
  border-radius: 50%;
  object-fit: cover;
}

.messageText {
  margin-right: 10px;
  width: 100%;
  max-width: 87%;
}

.messageText p {
  font-size: 14px;
  font-weight: 700;
  line-height: 16.71px;
  color: #ef0005;
  margin-bottom: 5px;
}

.messageText h3 {
  color: #4c4c4c;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.5px;
  letter-spacing: -0.5px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.messageDate {
  text-align: end;
  width: 100%;
  max-width: 13%;
}

.messageDate p {
  font-size: 13.11px;
  font-weight: 400;
  line-height: 15.65px;
  letter-spacing: -0.4057200253009796px;
  color: #e70108;
  margin-bottom: 0px;
}

.messageDate span {
  font-size: 11.1px;
  font-weight: 700;
  color: #ffffff;
  background: #1f1f1f;
  border-radius: 150px;
  padding: 2px 6px;
}

.profileText {
  width: 100%;
  padding-left: 10px;
}

.profileText h2 {
  font-size: 15px;
  line-height: 25.5px !important;
  color: #e90007 !important;
  text-transform: none;
}

.profileText p {
  font-size: 11.5px !important;
  font-weight: 400;
  color: #4c4c4c;
  margin: 0;
}

.redIcon {
  border-radius: 50%;
  height: 16px;
  width: 16px;
  background-color: #00a900;
  border: 2px solid #fff;
  position: absolute;
  left: 45px;
  bottom: 14px;
}

/* ----block_popup-css--- */
.securityIconDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.deleteHeading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}

.deleteHeading h2 {
  font-size: 25.08px;
  font-weight: 900;
}

.deleteHeading p {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0px;
  text-align: center;
}

.deleteBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}

.deleteBtn .deleteContinue {
  background: linear-gradient(169.09deg, #f40000 -9.18%, #7d0e0e 337.8%);
  font-size: 15px;
  font-weight: 700;
  height: 36px;
  padding: 0 15px;
  text-align: center;
  border-radius: 50px;
  border: none;
  color: white;
  margin-bottom: 10px;
}

.deleteBtn .deleteCancel {
  color: white;
  background-color: transparent;
  border: none;
  font-size: 15px;
  font-weight: 700;
}

@media only screen and (min-width: 768px) and (max-width: 858px) {
  .messageDate p {
    font-size: 12px;
  }

  .messageDate {
    max-width: 19%;
  }
}

@media only screen and (min-width: 410px) and (max-width: 585px) {
  .messageDate {
    max-width: 19%;
  }
}

@media only screen and (max-width: 409px) {
  .messageText h3 {
    font-size: 14px;
    line-height: 17.5px;
  }

  .messageInfo {
    padding: 8px;
  }

  .messageImg {
    max-width: 49px;
    margin-right: 10px;
  }

  .messageImg img {
    max-width: 49px;
  }

  .messageDate p {
    font-size: 11px;
  }

  .messageDate span {
    font-size: 9px;
  }

  .messageText p {
    font-size: 13px;
    margin-bottom: 2px;
  }

  .messageDate {
    max-width: 20%;
  }
}

.voiceNote {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.voiceNote img {
  margin-right: 10px;
}

.audioDuration {
  margin-left: 10px;
  font-size: 0.9rem;
  color: #555;
}