.hadingMainDiv {
  padding: 15px 30px;
}

.moduleHeding {
  display: flex;
  justify-content: center;
  align-items: center;
}

.moduleHeding h2 {
  font-size: 25.08px;
  font-weight: 900;
  text-align: right;
  margin-right: 10px;
  margin-bottom: 0px;
}

.moduleHeding span {
  margin-bottom: 20px;
}

.moduleHeding span svg {
  width: 25px;
  height: 25px;
}

.moduleHedingInner {
  margin-bottom: 25px;
}

.moduleHedingInner h2 {
  font-size: 17px;
  font-weight: 700;
  text-align: center;
}

.moduleHedingInner h2 span {
  font-family: Lato;
  font-size: 17px;
  font-weight: 400;
  text-align: center;
  line-height: 23px;
}

.flagBtn button {
  width: 100%;
  height: 40px;
  font-size: 15px;
  font-weight: 700;
  line-height: 18.29px;
  text-align: center;
  color: black;
  background-color: white;
  border-radius: 50px;
  margin-bottom: 15px;
  border: none;
}

.flagCancleButton {
  color: white !important;
  background: linear-gradient(169.09deg,
      #f40000 -9.18%,
      #7d0e0e 337.8%) !important;
}

/* ---flag close poupop css */
.hedingFlagClose {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.hedingFlagClose h2 {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.hedingFlagClose h2 span {
  font-size: 17px;
  font-weight: 700;
}

@media (max-width: 575px) {
  .MainBox {
    width: 100% !important;
    flex-shrink: 0;
    border-radius: 20px;
    background: #fff;
    padding: 10px;
  }
}