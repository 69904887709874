.footerSection {
  background: #000;
  border-top: 9px solid #d3010f;
  padding: 30px 0 50px;

}
.footerLinks {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.footerLinks a {
  font-size: 12.5px;
  font-weight: 400;
  line-height: 22px;
  color: #ffffff;
  margin: 0px 10px;
  text-transform: uppercase;
  text-decoration: none;
}
.verticleLine {
  border-left: 1px solid #fff;
  height: 11px;
  margin-right: 5px;
}

.appSection img {
  margin: 0px 7px;
}
.appSection .AppGallery {
  width: 42.47px;
  height: 42.47px;
}
.footerLinks p {
  font-size: 12.5px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  margin: 0px 10px;
}

.footerLinks a:hover {
  color: #d3010f;
}

.footerLinks a:focus {
  color: #d3010f;
}
.textFooter a {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  text-decoration: none !important;
}

.textFooter a:hover {
  color: #ffffff;
}

.textFooter a:focus {
  color: #ffffff;
}

@media only screen and (max-width: 991px) {
  .appSection {
    margin-top: 20px;
  }
  .verticleLine {
    display: none;
  }
  .footerLinks {
    flex-direction: column;
    align-items: flex-start;
  }
  .footerLinks a {
    margin-top: 6px;
  }
  .footerLinks p {
    margin-top: 10px;
  }
  .textFooter a {
    text-align: left;
    margin-left: 10px;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .appSection {
    display: grid;
  }
  .appSection img {
    margin: 5px 0px;
  }

  .appSection .AppGallery {
    width: 42.47px;
    height: 42.47px;
  }
}
