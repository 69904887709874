.headerSection {
  background-color: #ed0107;
  margin-left: auto;
  margin-right: auto;
  position: fixed !important;
  top: 0;
  width: 100%;
  z-index: 9999;
}
.headerLogo {
  position: relative;
}
.headerLogo::after {
  background: hsla(0, 0%, 100%, 0.7);
  content: "";
  height: 70%;
  position: absolute;
  right: -14px;
  top: 51%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  width: 1px;
}
.activeShow {
  color: #ffffff;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 16px;
  cursor: pointer;
}
.activeShow svg {
  margin-right: 5px;
  margin-left: 7px;
  color: #ffffff;
  width: 24px;
  height: 19px;
}

.headerIcon {
  margin-left: 19px !important;
  color: #ffffff;
  width: 26px !important;
  height: 21px !important;
  cursor: pointer;
}
.profileImg {
  height: 50px;
  border-radius: 50px;
  margin-left: 10px;
  position: relative;
}
.profileImg svg {
  color: black;
  width: 36px;
  height: 52px;
  cursor: pointer;
}
.profileImg img {
  width: 46px;
  height: 46px;
  border-radius: 50px;
  cursor: pointer;
}
.navbarToggle {
  outline: unset !important;
  border: unset !important;
}
.navbarToggle:focus {
  text-decoration: none;
  outline: unset !important;
  box-shadow: unset !important;
}
.navbarToggle svg {
  color: #ffffff;
  font-size: 30px;
  font-weight: bold;
}
.navItems {
  display: flex;
  align-items: center;
}
.searchOption {
  display: flex;
  align-items: center;
}
.searchOptionToggle {
  display: flex;
  align-items: center;
  position: relative;
}
.searchOptionToggle svg {
  cursor: pointer;
}
.searchCancel {
  width: 100%;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  justify-content: flex-start;
}
.searchBox {
  position: relative;
  width: 100%;
}

.searchInput {
  box-shadow: 0px 2px 2px 0px #00000026;
  border: 1px solid #e7e7e7 !important;
  border-radius: 110px !important;
  padding-left: 43px !important;
  width: 100% !important;
}

.searchInput:focus {
  border-color: #e7e7e7 !important;
  box-shadow: unset !important;
}
.searchInput::placeholder {
  color: #999999 !important;
  font-size: 15px;
  font-weight: 300;
  padding-left: px;
}

.searchBox svg {
  position: absolute;
  top: 7px;
  left: 0px;
  width: 25.35px;
  height: 25.35px;
  color: #333 !important;
}
.searchCancel svg {
  font-size: 25px;
  color: #ffffff;
  margin-left: 10px;
}
@media only screen and (max-width: 991px) {
  .searchOption {
    display: none;
  }
  .navItems {
    margin-top: 20px;
  }
  .navbarToggle svg {
    margin-left: 8px !important;
  }
  .searchCancel {
    width: unset;
  }
}

@media only screen and (min-width: 992px) {
  .navOptionHide {
    display: none;
  }
}
@media only screen and (min-width: 768px) {
  .responseLogo {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .mainLogo {
    display: none;
  }
  .responseLogo {
    width: 36px;
    height: 36px;
  }
  .headerLogo::after {
    display: none;
  }
}
@media only screen and (max-width: 575px) {
  .navItems {
    flex-direction: column;
    text-align: center;
  }
  .activeShow a {
    padding: 10px;
  }
}
@media only screen and (max-width: 500px) {
  .headerBrand {
    margin-right: 0px !important;
  }
  .searchInput {
    width: 100%;
    max-width: 150px;
  }
  .searchCancel {
    margin-left: 0px;
  }
  .profileImg svg {
    width: 30px;
  }
  .profileImg img {
    width: 40px;
    height: 40px;
  }
  .navbarToggle svg {
    font-size: 28px;
    margin-left: 5px !important;
  }
}

/* TooltipComponent.module.css */
.tooltipMianDiv {
  background-color: white;
  opacity: 999;
  width: 200px;
  position: absolute;
  top: 134%;
  right: 0%;
  border: 1px solid #dcdcdc;
  box-shadow: 2px 2px 6px 0px #00000080;
  text-align: start;
  color: black;
  z-index: 99999;
}
.tooltipTopBackground {
  background-color: black;
  height: 6px;
}
.tooltipUserName {
  padding: 10px;
}
.tooltipUserName h2 {
  font-size: 16px;
  font-weight: 400;
  color: #e50109;
  margin-bottom: 3px;
}

.tooltipUserName h3 {
  font-size: 17px;
  font-weight: 700;
  flex-wrap: wrap;
  margin-bottom: 0px;
  word-break: break-word;
}
.hrLine {
  height: 1px;
  background-color: #dadada;
  margin: 0px;
}

.tooltipUserReport {
  padding: 10px;
}

.tooltipUserReport h1 {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 3px;
  text-transform: uppercase;
}
.tooltipUserReport a {
  margin-bottom: 0px;
  text-decoration: none;
}
.tooltipUserReport a p {
  margin-bottom: 0px;
  color: #000000;
  font-size: 14px;
  font-weight: 400;
}
.tooltipUserReport a p:hover {
  color: red;
}
.tooltipLogout {
  height: 26px;
}

.tooltipLogout button {
  padding: 0px 10px;
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-weight: 500;
  text-align: start;
  background: none;
  border: none;
  color: #000000;
  background-color: #cccccc;
  text-transform: uppercase;
}
.tooltipLogout button:hover {
  background-color: #000000;
  color: white;
}
.tooltipSwitchAccount {
  padding: 10px 10px;
}

.tooltipSwitchInner {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 5px;
}
.tooltipSwitchInner h1 {
  font-size: 14px;
  font-weight: 700;
  margin-right: 10px;
  margin: 0px;
  margin-right: 10px;
}
.tooltipSwitchInner img {
  width: 11px;
  height: 11px;
}

.tooltipSwitchAccountNav a {
  margin-bottom: 0px;
  text-decoration: none;
}

.tooltipSwitchAccountNav a p {
  margin-bottom: 0px;
  color: #000000;
  font-size: 14px;
  font-weight: 400;
}
.tooltipSwitchAccountNav a p:hover {
  color: red;
}

.activeShow {
  position: relative;
}

.messageCountCercle span {
  position: absolute;
  top: -11px;
  right: -15px;
  background-color: #000000;
  padding: 6px 6px 6px 6px;
  z-index: 2222;
  width: auto;
  height: 23px;
  min-width: 23px;
  border-radius: 100%;
  font-family: Helvetica;
  font-size: 11.1px;
  font-weight: 700;
  line-height: 12.76px;
  text-align: center;
  color: white;
}

.dropDownCount {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dropDownCount {
  color: black;
}
.dropDownCount span {
  color: red;
}

.headerIcon {
  position: relative;
}
@media (min-width: 576px) and (max-width: 991px) {
  .activeShow {
    margin-right: 8px;
  }
}

.messageCountCercleNew span {
  position: absolute;
  top: -11px;
  right: -5px;
  background-color: #000000;
  padding: 6px 6px 6px 6px;
  z-index: 2222;
  width: auto;
  height: 23px;
  min-width: 23px;
  border-radius: 100%;
  font-family: Helvetica;
  font-size: 11.1px;
  font-weight: 700;
  line-height: 12.76px;
  text-align: center;
  color: white;
}

@media (max-width: 991px) {
  .messageCountCercleNew span {
    top: -11px;
    right: -14px;
  }
  .tooltipUserReport a p {
    padding: 2px 0px;
  }
}

@media (max-width: 575px) {
  .messageCountCercle span {
    top: -3px;
    right: -19px;
  }
  .messageCountCercleNew span {
    top: -3px;
    right: -19px;
  }
  .activeShow {
    padding: 4px 0px;
  }
}
.tooltipMianDivS {
  width: 300px !important;
  top: 223% !important;
}

.displayOptionss {
  background: #e4e4e4;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  padding: 7px 12px 10px;
  text-transform: uppercase;
  border-radius: 5px;
  margin-bottom: 4px !important;
}
.displayOptionss p {
  margin-bottom: 0px;
  font-size: 14px;
  margin-left: 7px;
  font-weight: 300;
  margin-top: 2px;
}

.bottomSelect {
  text-align: center;
}
.bottomSelect span {
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  padding-left: 11px;
  padding-right: 11px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  -webkit-text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
}
.bottomSelectLeft {
  border-right: 1px solid rgba(0, 0, 0, 0.3);
}

.conformBtns {
  margin: 0 5px 1px 0;
  padding: 10px;
}
.conformBtns button {
  background: linear-gradient(169.09deg, #f40000 -9.18%, #7d0e0e 337.8%);
  font-size: 15px;
  font-weight: 700;
  line-height: 18.29px;
  text-align: center;
  border: none;
  padding: 0px 25px;
  border-radius: 50px;
  color: white;
  height: 40px;
  width: 100%;
  text-transform: uppercase;
}

.messageBox {
  border-radius: 5px;
  cursor: pointer;
}

.messageInfo {
  background: #ffffff;
  padding: 15px;
  margin-top: 2px;
  display: flex;
  align-items: center;
}

.messageInfo:hover {
  /* background-color: hsl(0, 0%, 97%); */
  background-color: #f5f5f5;
  transition: 0.1s ease;
}

.messageImg {
  width: 100%;
  max-width: 57.62px;
  border-radius: 100%;
  margin: auto 0px;
  cursor: pointer;
}

.messageImg img {
  height: 56px;
  min-width: 56px;
  overflow: hidden;
  width: 56px;
  border-radius: 50%;
  object-fit: cover;
}
.messageImg p {
  margin-bottom: 0px;
}
.bgTopDiv {
  background: #d6d6d6;
  padding: 20px;
}
.bgTopDivDisplay {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bgTopDivDisplay p {
  color: #000;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 0px;
  letter-spacing: 2px;
}
.bgTopDivDisplay p span {
  color: #d3010f;
}
.cancelBtns button {
  background: linear-gradient(169.09deg, #f40000 -9.18%, #7d0e0e 337.8%);
  font-size: 15px;
  font-weight: 700;
  line-height: 18.29px;
  text-align: center;
  border: none;
  border-radius: 50px;
  color: white;
  text-transform: uppercase;
}
.cancelBtns svg {
  margin-top: -3px;
}
.topSpace {
  margin-top: 20px;
  margin-bottom: 20px;
}

.messageText {
  margin-left: 10px;
}
.messageText p {
  font-size: 15px;
  font-weight: 700;
  line-height: 16.71px;
  color: #000;
  margin-bottom: 0px;
}
.messageText span {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.71px;
  color: #ef0005;
}
.picShow {
  width: 100%;
  max-width: 600px;
  margin: 10px auto;
  overflow: hidden;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
}
.picShow img {
  width: 100%;
  max-width: 600px;
  height: 300px;
  object-fit: cover;
  display: block;
}
.showVideo {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  overflow: hidden;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}
.showVideo video {
  width: 100%;
  height: 350px;
  display: block;
}

.closeIcon {
  cursor: pointer;
}

.searchInput::-webkit-search-cancel-button {
  cursor: pointer;
}

@media (max-width: 335px) {
  .navbarToggle {
    margin-right: 0px;
  }
}
