.datefilter {
  background: #ffffff;
  padding: 15px 10px;
  margin-top: 29px;
  margin-bottom: 2px;
  border-radius: 5px;
}
.inputSelect {
  color: red !important;
  border: unset !important;
  background: transparent;
}
.inputSelect option {
  color: black;
}
.inputSelect:focus-visible {
  outline: unset !important;
}
.reportSection {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  align-items: flex-start;
  padding: 10px;
  margin-bottom: 2px;
}

.reportSection:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.reportImg {
  width: 100%;
  max-width: 63.23px;
  border-radius: 100%;
  margin-right: 15px;
}
.reportImg img {
  width: 100%;
  max-width: 63.23px;
  cursor: pointer;
  border-radius: 100%;
  object-fit: cover;
}
.reportText {
  margin-right: 12px;
  cursor: pointer;
}
.reportText p {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.10000000149011612px;
  color: #000000;
}
.reportText p strong {
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: -0.10000000149011612px;
}
.reportText p span {
  color: #db0429;
}
.reportText .reportShow {
  display: flex;
  align-items: center;
}
.reportText .reportShow p {
  margin-right: 20px;
  margin-bottom: 0px;
  color: #141414;
  font-size: 11.44px;
  font-weight: 400;
  line-height: 13.73px;
  letter-spacing: -0.12319999933242798px;
}
.reportText .reportShow img {
  margin-bottom: 2px;
}

@media only screen and (max-width: 400px) {
  .reportSection {
    padding: 7px;
    flex-wrap: wrap;
  }
  .reportImg {
    margin-right: 10px;
  }
  .reportImg img {
    max-width: 48.23px;
  }
  .reportText p {
    font-size: 12px;
  }
  .reportText p strong {
    font-size: 12px;
  }
  .reportText .reportShow p {
    font-size: 8px;
  }
}

.reportSectionLeft {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

/* ----location-map-css */
.location {
  padding: 0px 10px 0px 10px;
  height: 270px;
  margin-bottom: 20px;
}

.locationreportDetails {
  padding: 0px 10px 0px 10px;
  height: 270px;
  margin-bottom: 20px;
}

.location h5 {
  color: #d30000;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.location .inputGroup {
  background: #4c4c4c;
  color: #ffffff;
  height: 38px;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.location input {
  background: #f6f6f6;
  height: 38px;
  border: unset;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  padding-left: 11px;
  width: 100%;
}

.location input:focus {
  box-shadow: unset !important;
  background: #f6f6f6;
}

.location input::placeholder {
  color: #4c4c4c;
}

.location p {
  color: #4c4c4c;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.2px;
  margin-bottom: 0px;
}

.googleMapSet {
  margin: 15px -10px 10px;
  border: 1px solid #cbcbcb;
}

.reportLeftSide {
  background: #ffffff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.reportDetText h1 {
  font-size: 23px;
  font-weight: 700;
  line-height: 27.6px;
  color: #000000;
  text-transform: uppercase;
}

.reportDetText span {
  font-size: 17px;
  font-weight: 700;
  line-height: 20.29px;
  color: #ff0909;
  display: flex;
}

.reportDetText span p {
  font-size: 17px;
  font-weight: 400;
  line-height: 20.29px;
  margin-bottom: 0px;
  color: #000000;
}

.reportDetText h3 {
  font-size: 17px;
  font-weight: 400;
  line-height: 19px;
  color: #303c42;
  margin-bottom: 15px;
  margin-top: 15px;
}

.reportIconData {
  display: flex;
  flex-wrap: wrap;
}

.reportIconData span {
  font-size: 13.1px;
  font-weight: 400;
  line-height: 15.72px;
  color: #000000;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 5px;
}

.reportIconData span svg {
  font-size: 16px;
  margin-right: 5px;
  text-align: start;
  margin-top: -1px;
}

.reportIconData span:hover {
  color: #d30000 !important;
}

.reportIconData span p {
  font-size: 13.1px;
  font-weight: 400;
  line-height: 15.72px;
  margin-bottom: 0px;
}

.reportMainDesc {
  margin-top: 15px;
}

.reportMainDesc p {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  color: #303c42;
  margin-bottom: 0px;
  word-break: break-word;
}

.reportRightAd {
  width: 100%;
  height: 280px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Lato;
  font-size: 21px;
  font-weight: 700;
  text-align: center;
}

.googleMapRight {
  margin: 0px 0px 0px 0px;
  border: 1px solid #cbcbcb;
  margin-bottom: 5px;
}

.googleMapRightMain {
  text-align: center;
  margin-bottom: 20px;
}

.dashboardInfo {
  background: #ffffff;
  padding: 15px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  border-radius: 5px;
}

.dashboardImg {
  width: 100%;
  max-width: 130px;
  border-radius: 100%;
  position: relative;
}

.dashboardImg img {
  width: 100%;
  max-width: 130px;
  object-fit: cover;
  border-radius: 100%;
}

.dashboardText p {
  color: #e90202;
  font-size: 17px;
  font-weight: 500;
  line-height: 20.29px;
  margin-bottom: 3px;
}

.dashboardText h3 {
  color: #000000;
  font-size: 24px;
  font-weight: 500;
  line-height: 27px;
  margin-bottom: 12px;
  word-break: break-word;
}

.dashboardInfoBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.dashboardText h5 {
  color: #e90202;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.dashboardText h5 strong {
  color: #e90202;
  font-size: 15px;
  font-weight: 700;
}

/* -- */

.optionLabel {
  display: block;
  margin-bottom: 5px;
}

/* Style for checkbox, you can adjust as per your need */
.optionLabel input[type="checkbox"] {
  margin-right: 5px;
}

/* ------report-details-slider--- */
.reportDetailsSlider {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  margin-top: 20px;
}

.reportSlide {
  height: 140px;
  margin-top: 45px;
}

.arrrowLeftReportDetails {
  position: absolute;
  color: white;
  z-index: 1;
  top: 0%;
  left: 0px;
  background: #303c42;
  height: 48px;
  width: 48px;
  border-radius: 2px;
  cursor: pointer;
}
.arrrowLeftReportDetails svg {
  position: absolute;
  top: 23%;
  left: 0px;
  width: 45px;
  height: 29px;
}

.arrrowRightReportDetails {
  position: absolute;
  color: white;
  z-index: 1;
  top: 0%;
  right: 0px;
  background: #303c42;
  height: 48px;
  width: 48px;
  border-radius: 2px;
  cursor: pointer;
}
.arrrowRightReportDetails svg {
  position: absolute;
  top: 23%;
  right: 0px;
  width: 45px;
  height: 29px;
}

.reportDetailsSlider .reportText {
  margin-top: 20px;
  margin-right: 0px;
}
.reportDetailsSlider .reportText h3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 21.48px;
  color: #141414;
}
.reportDetailsSlider .reportText span {
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 15.5px;
  font-weight: 700;
  color: #ff0909;
  margin-bottom: 10px;
}

.reportDetailsSlider .reportRightSliderText .reportText {
  text-align: end;
}

.reportDetailsSlider .reportRightSliderText span {
  justify-content: end;
}

.reportLeftAd {
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Lato;
  font-size: 27px;
  font-weight: 300;
  color: #000000;
  overflow: hidden;
  position: relative;
}

/* ---persion-details */
.persionReportDetails {
  background-color: #000000;
  margin-bottom: 2px;
  padding: 10px 20px;
  border-radius: 5px 5px 0px 0px;
}
.persionReportDetails h2 {
  font-size: 17px;
  font-weight: 400;
  line-height: 19px;
  color: white;
  text-transform: capitalize;
}
.persionReportDetails span {
  display: flex;
  justify-content: start;
  font-size: 17px;
  font-weight: 400;
  line-height: 19px;
  color: white;
}
.persionReportDetails span p {
  margin-bottom: 0px;
}

.persionFound {
  background-color: #e90202;
  color: white;
  padding: 10px 20px;
  border-radius: 0px 0px 5px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.persionFound svg {
  margin-right: 10px;
  font-size: 20px;
}

.persionFound span {
  font-size: large;
}

.vehicleReportDetails .persionReportDetails {
  border-radius: 5px 5px 5px 5px;
  text-transform: uppercase;
}

.crudIconHover {
  visibility: hidden;
}

.reportSection:hover .crudIconHover {
  visibility: initial !important;
}

/* ----delete_popup-css--- */
.securityIconDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}
.deleteHeading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}

.deleteHeading h2 {
  font-size: 25.08px;
  font-weight: 900;
}
.deleteHeading p {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0px;
  text-align: center;
}
.deleteBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}

.deleteBtn .deleteContinue {
  background: linear-gradient(169.09deg, #f40000 -9.18%, #7d0e0e 337.8%);
  font-size: 15px;
  font-weight: 700;
  height: 36px;
  padding: 0 15px;
  text-align: center;
  border-radius: 50px;
  border: none;
  color: white;
  margin-bottom: 10px;
  width: 60%;
}

.deleteBtn .deleteCancel {
  color: white;
  background-color: transparent;
  border: none;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
}

.loadMoreSectionComment {
  text-align: center;
  color: #db0429;
  margin-top: 20px;
}
.loadMoreSectionComment span {
  cursor: pointer;
}

/* ---- */
.priveteIcon {
  position: absolute;
  bottom: 0%;
  right: 0%;
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: linear-gradient(169.09deg, #f40000 -9.18%, #7d0e0e 337.8%);
}
.priveteIcon svg {
  color: white;
  font-size: 21px;
}
.priveteMessage {
  display: flex;
  justify-content: start;
  align-items: start;
  background: #303c42;
  padding: 12px 10px 0px 10px;
  border-radius: 5px;
  color: white;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.priveteMessage h2 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 12px;
}

.priveteMessage h2 p {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.priveteMessage h2 p svg {
  font-size: 20px;
  color: white;
}

/* -----timelineScroll--- */
.scrollTopTimeline {
  max-height: 130vh;
  overflow-y: auto;
  margin-bottom: 15px;
}
.scrollTopTimeline::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.scrollTopTimeline::-webkit-scrollbar-track {
  background-color: white;
}

/* Handle */
.scrollTopTimeline::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* -----yourreportDetails----- */
.scrollTopYourReport {
  max-height: 91vh;
  overflow-y: auto;
  margin-bottom: 15px;
}
.scrollTopYourReport::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.scrollTopYourReport::-webkit-scrollbar-track {
  background-color: white;
}

/* Handle */
.scrollTopYourReport::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* -----yourReportDetails-chat---- */
.scrollTopChat {
  max-height: 90vh;
  overflow-y: auto;
  margin-bottom: 15px;
  margin-top: 20px;
}
.scrollTopChat::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.scrollTopChat::-webkit-scrollbar-track {
  background-color: white;
}

/* Handle */
.scrollTopChat::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

.scrollTopChat:hover::-webkit-scrollbar-thumb {
  background: rgb(204, 204, 204); /* Adjust color for hover effect */
}

/* ---reportdetaisl--view--map---- */

.googleMapRightMain button {
  border: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  color: #000000;
}

@media only screen and (max-width: 375px) {
  .dashboardImg img {
    max-width: 105px;
  }
  .dashboardImg {
    max-width: 110px;
  }
  .dashboardText h5 {
    font-size: 15px;
  }
}

.crudIconBookmarkHover {
  visibility: hidden;
}

/* ------- */

.reportSocialIcon {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0px 15px;
  border-radius: 5px;
}

.reportSocialIcon span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  margin: 10px 15px;
  text-transform: uppercase;
  cursor: pointer;
  color: #303c42;
}

.reportSocialIcon span img,
.reportSocialIcon span svg {
  width: 28px;
  height: 28px;
  font-weight: bold;
}

/* Hover effects for the first and second span */
.reportSocialIcon span:nth-child(1):hover,
.reportSocialIcon span:nth-child(2):hover {
  color: red;
}

.reportSocialIcon span:nth-child(1):hover svg,
.reportSocialIcon span:nth-child(2):hover svg {
  fill: red !important;
  color: red !important;
}

.shareLinkText {
  cursor: initial;
}

.reportSocialIcon span:nth-child(4):hover svg {
  fill: #425eac !important;
  color: #425eac !important;
}

.reportSocialIcon span:nth-child(6):hover svg {
  fill: #41b153 !important;
  color: #41b153 !important;
}

/* Hover effects for the remaining spans */
.reportSocialIcon span:hover {
  color: inherit;
}

.reportSocialIcon span:hover svg {
  fill: inherit !important;
  color: inherit !important;
}

@media (max-width: 850px) {
  .reportSocialIcon {
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 5px;
  }
}

@media (max-width: 768px) {
  .reportSocialIcon {
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
  }

  .reportSocialIcon span {
    margin: 5px 0;
  }
}

@media (max-width: 500px) {
  .reportSocialIcon {
    flex-direction: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 5px 5px;
  }

  .reportSocialIcon span {
    margin: 6px 12px;
  }
}

.reportSection:hover {
  background-color: #f5f5f5;
  /* background-color: hsl(0, 0%, 97%); */
  transition: 0.1s ease;
}

.videoContainerComment video {
  object-fit: cover;

  height: 210px !important;
  border-radius: 10px;
}

.reportInfo {
  display: flex;
  justify-content: end;
  align-items: center;
}

@media (max-width: 425px) {
  .reportSlide {
    height: 121px;
    margin-top: 45px;
  }
}

.deleteBtnAdd {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
}
.deleteBtnAdd .deleteContinue {
  background: linear-gradient(169.09deg, #f40000 -9.18%, #7d0e0e 337.8%);
  font-size: 15px;
  font-weight: 700;
  height: 36px;
  padding: 0 30px;
  text-align: center;
  border-radius: 50px;
  border: none;
  color: white;
  margin-bottom: 10px;
  width: 36%;
  text-transform: uppercase;
}
.deleteBtnAdd .deleteCancel {
  background: white;
  font-size: 15px;
  font-weight: 700;
  height: 36px;
  padding: 0 30px;
  text-align: center;
  border-radius: 50px;
  border: none;
  color: black;
  margin-bottom: 10px;
  width: 36%;
  text-transform: uppercase;
}

.deleteHeadingSpace {
  margin-top: 0px !important;
}

/* ------report-details-slider--- */
.reportAdds {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.reportAdds h3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 21.48px;
  color: #141414;
}
.reportAdds span {
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 15.5px;
  font-weight: 700;
  color: #ff0909;
  margin-bottom: 10px;
}
.reportAdds p {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.10000000149011612px;
  color: #000000;
  margin-bottom: 0px;
}
.dashboardInfoBtn .followButton {
  background: linear-gradient(169.09deg, #f40000 -9.18%, #7d0e0e 337.8%);
  text-transform: uppercase;
  border: none;
  border-radius: 20px;
  width: 144px;
  height: 37px;
  margin-right: 15px;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 700;
  line-height: 17.07px;
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}
.dashboardInfoBtn .followButtonadd {
  background: linear-gradient(169.09deg, #f40000 -9.18%, #7d0e0e 337.8%);
  text-transform: uppercase;
  border: none;
  border-radius: 20px;
  width: 144px;
  height: 37px;
  margin-right: 15px;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 700;
  line-height: 17.07px;
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}
.dashboardInfoBtn .followButton::after {
  content: "UNFOLLOW";
  position: absolute;
  border: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.dashboardInfoBtn .followButton:hover {
  background: linear-gradient(169.09deg, #f40000 -9.18%, #7d0e0e 337.8%);
}

.dashboardInfoBtn .followButton:hover::after {
  opacity: 1;
}

.dashboardInfoBtn .unfollowButton {
  background-color: #000000;
  text-transform: uppercase;
  border-radius: 20px;
  border: none;
  width: 144px;
  height: 37px;
  margin-right: 15px;
  color: white;
  font-size: 14px;
  font-weight: 700;
  line-height: 17.07px;
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

/* .dashboardInfoBtn .unfollowButton::after {
  content: "FOLLOW";
  position: absolute;
  border: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(169.09deg, #f40000 -9.18%, #7d0e0e 337.8%);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
} */

.dashboardInfoBtn .unfollowButton:hover {
  background-color: #000000;
}

.dashboardInfoBtn .unfollowButton:hover::after {
  opacity: 1;
}

.dashboardImg .anonymousImage {
  width: 100%;
  max-width: 115px;
  border-radius: 100%;
}

.anonymousTestDetails h3 {
  font-size: 23px;
  font-weight: 700;
  color: #000000;
}

.anonymousTestDetails p {
  color: #d81010;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 0px;
}

/* ----records Found */
.records_Found_Message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
  background-color: white;
  border-radius: 5px;
}

.records_Found_Message h2 {
  font-size: 20px;
  font-weight: 500;
  color: #373a3c;
}

.sliderFormateDate {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sliderFormateDate p {
  margin-bottom: 0px;
  color: #ff0909;
  font-weight: 700;
}
.deleteHeadings h2 {
  font-size: 25.08px;
  font-weight: 900;
  text-align: center;
}
.deleteHeadings p {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0px;
  text-align: center;
  margin-bottom: 10px;
}
.popupImgSet img {
  width: 100%;
  max-width: 59px;
  margin-bottom: 15px;
}
