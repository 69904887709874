.alertLocation {
  margin-top: 29px;
  background: #ffffff;
  border-radius: 5px;
}
.alertLocation .alertLocationText {
  padding: 20px;
}
.alertLocation .alertLocationText h5 {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #000000;
  margin-bottom: 0px;
}
.alertLocation .alertLocationText h6 {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
  color: #e90007;
  margin-bottom: 0px;
  margin-top: 10px;
}
.locationBox {
  border-top: 2px solid #efefef;
  padding: 20px;
}
.locationBox button {
  width: 100% !important;
  max-width: 109px !important;
}
.alertSection {
  border-top: 2px solid #efefef;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
.alertSection .alertText {
  margin-right: 12px;
}
.alertSection .alertText h5 {
  font-size: 15px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: -0.10000000149011612px;
  color: #000000;
  margin-bottom: 3px;
  line-break: anywhere;
}
.alertSection .alertText h6 {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.10000000149011612px;
  color: #000000;
  margin-bottom: 0px;
}
.alertInfo {
  padding: 20px;
}
.showCenter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.showCenter h6 {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  color: #010101;
  margin-bottom: 0px;
}
.showCenter p {
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: 400;
  line-height: 19.2px;
  color: #4c4c4c;
}
.showCenter p strong {
  font-size: 15px;
  font-weight: 700;
  line-height: 19.2px;
  margin-left: 10px;
}
.labelAdd strong {
  color: red;
  width: 20px;
}
.crimeNotify {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 2px solid #efefef;
  padding: 20px;
}
.crimeNotify .crimeNotifySpace {
  width: 100%;
  max-width: 75%;
}
.crimeNotify h5 {
  font-size: 15px;
  font-weight: 700;
  line-height: 17.9px;
  color: #000000;
  margin-bottom: 5px;
}
.crimeNotify p {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #000000;
  margin-bottom: 0px;
}

@media only screen and (max-width: 410px) {
  .crimeNotifyDispaly {
    display: block;
  }
  .crimeNotify .crimeNotifySpace {
    max-width: unset;
    margin-right: 12px;
  }
  .showCenter {
    display: block;
  }
  .showCenter p {
    font-size: 12px;
    margin-top: 10px;
  }
  .showCenter p strong {
    font-size: 12px;
    margin-left: 0px;
  }
}
@media only screen and (min-width: 411px) and (max-width: 421px) {
  .showCenter p {
    font-size: 13px;
  }
  .showCenter p strong {
    font-size: 13px;
  }
  .showCenter h6 {
    font-size: 13px;
  }
}
/* -----alertLocationScroll--- */
.scrollTopAlertLocation {
  max-height: 76vh;
  overflow-y: auto;
}
.scrollTopAlertLocation::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.scrollTopAlertLocation::-webkit-scrollbar-track {
  background-color: white;
}

/* Handle */
.scrollTopAlertLocation::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

.alertSection:hover {
  background-color: #f5f5f5;
  /* background-color: hsl(0, 0%, 96%); */
  transition: 0.1s ease;
}

.currentLocationButton {
  position: absolute;
  left: 12px;
  bottom: 12px !important;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: black;
  cursor: pointer;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}
