.PageNotFound {
  text-align: center;
  margin-top: 70px;
}
.PageNotFound h1 {
  color: #000000;
  font-weight: 400;
  font-size: 54px;
}
.PageNotFound h1 strong {
  font-weight: 700;
}
.PageNotFound p {
  color: #000000;
  font-weight: 400;
  font-size: 24px;
}
.PageNotFound img {
  width: 100%;
  max-width: 100px;
  margin-bottom: 15px;
}

@media (max-width: 575px) {
  .PageNotFound h1 {
    font-size: 33px;
  }
  .PageNotFound p {
    font-size: 20px;
  }
}
