.profileInfo {
  background: #ffffff;
  padding: 15px;
  margin-top: 29px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}

.profileImg {
  width: 100%;
  max-width: 130px;
  border-radius: 100%;
  margin-right: 15px;
}

.profileImg img {
  width: 100%;
  max-width: 130px;
  border-radius: 150px;
}

.profileText p {
  color: #e90202;
  font-size: 17px;
  font-weight: 500;
  line-height: 20.29px;
  margin-bottom: 3px;
}

.profileText h3 {
  color: #000000;
  font-size: 24px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: -0.20000000298023224px;
  margin-bottom: 12px;
  word-break: break-word;
}

.profileText h5 {
  color: #e90202;
  font-size: 17px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
}

.profileText h5 strong {
  color: #e90202;
  font-size: 17px;
  font-weight: 700;
  line-height: 18px;
}

.profileText h6 {
  color: #d70000;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.profileBox {
  background: #ffffff;
  margin-top: 20px;
  border-radius: 5px;
}

.profileBox p {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.2px;
  color: #818a91;
  margin-bottom: 0px;
}

.profileBox h6 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #d30000;
}

.profileDiv {
  padding: 20px;
}

.profileWidth {
  width: 100%;
  max-width: 419px;
}

.profileFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profileSection {
  border-top: 2px solid #efefef;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profileSection h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 26px;
  color: #373a3c;
}

.profilePicture {
  display: flex;
  align-items: center;
}

/* edit profile css */
.uploadSection {
  width: 100%;
  max-width: 144px;
  height: 144px;
  background: black;
  border-radius: 50%;
  margin-right: 30px;
  position: relative;
  overflow: hidden;
}

.uploadInputImg {
  width: 100%;
  max-width: 144px;
  height: 144px;
  border-radius: 50%;
  box-shadow: 0px 0px 4px 0px #00000033;
  background: white;
  position: relative;
}

.uploadInputImg img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: white;
}

.uploadInputImg button {
  position: absolute;
  bottom: 0;
  left: 0;
  border: none;
  background-color: #ed0107;
  width: 100%;
  height: 30px;
  color: #ffffff;
  border-radius: 0px 0px 100% 100%;
  font-weight: 700;
  outline: none;
}

.uploadIcon {
  margin-top: 44px;
  text-align: center;
}

.uploadIcon svg {
  color: #ffffff;
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.uploadIcon p {
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  text-align: center;
  color: #ffffff;
  margin-top: 10px;
}

@media only screen and (max-width: 400px) {
  .profileImg {
    max-width: 90px;
    margin-right: 10px;
  }

  .profileImg img {
    max-width: 90px;
  }

  .profileText p {
    font-size: 14px;
  }

  .profileText h3 {
    font-size: 18px;
    line-height: 21px;
  }

  .profileText h5 {
    font-size: 15px;
  }

  .profileText h5 strong {
    font-size: 15px;
  }

  .profileText h6 {
    font-size: 10px;
    line-height: 14px;
  }

  .uploadSection {
    max-width: 120px;
    height: 120px;
    margin-right: 20px;
  }

  .uploadInputImg img {
    max-width: 120px;
    height: 120px;
  }

  .profilePicture button {
    max-width: 102px;
  }

  .uploadIcon {
    margin-top: 29px;
  }

  .profileBox p {
    font-size: 11px;
    line-height: 16.2px;
  }

  .profileBlock {
    display: block !important;
  }

  .dashboardInfoBtn .followButton {
    padding: 10px 15px !important;
    margin-right: 12px !important;
    margin-top: 8px !important;
  }
  .dashboardInfoBtn .unfollowButton {
    padding: 10px 10px !important;
    margin-right: 18px !important;
    margin-top: 8px !important;
  }
}

@media only screen and (max-width: 500px) {
  .profileDiv {
    padding: 20px 33px 20px 18px;
  }

  .profileSection {
    padding: 20px 33px 20px 18px;
  }

  .profileSection h2 {
    font-size: 20px;
  }

  .profileBox h6 {
    font-size: 14px;
  }
}

.topHeader p {
  color: #d50000;
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 400;
}

.topHeader span {
  color: #818a91;
  font-size: 16px;
  font-weight: 400;
  text-transform: lowercase;
}
.topHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: #ffffff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 20px;
  position: relative;
}

.dashboardInfo {
  background: #ffffff;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.dashboardImg {
  display: flex;
  align-items: center;
}

.imageSection {
  width: 100%;
  max-width: 100px;
  border-radius: 100%;
  position: relative;
  margin: 0px auto;
}

.imageSection img {
  width: 100%;
  max-width: 130px;
  object-fit: cover;
  border-radius: 100%;
}

.textSection p {
  color: #e90202;
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 3px;
  text-align: center;
  margin-top: 5px;
}

.textSection h3 {
  color: #000000;
  font-size: 24px;
  font-weight: 500;
  line-height: 27px;
  margin-bottom: 12px;
}

.dashboardText h5 {
  color: #e90202;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 0px;
  text-align: center;
  margin-top: 10px;
}

.dashboardText h5 strong {
  color: #e90202;
  font-size: 15px;
  font-weight: 700;
}

.dashboardInfoBtn .followButton {
  background: linear-gradient(169.09deg, #f40000 -9.18%, #7d0e0e 337.8%);
  font-size: 14px;
  font-weight: 700;
  line-height: 17.07px;
  text-transform: uppercase;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  margin-right: 15px;
  color: white;
  margin-top: 5px;
}
.dashboardInfoBtn .unfollowButton {
  background-color: #000000;
  font-size: 14px;
  font-weight: 700;
  line-height: 17.07px;
  text-transform: uppercase;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  margin-right: 15px;
  color: white;
  margin-top: 5px;
}

.emailShow h6 {
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  margin-top: 5px;
  text-align: center;
  margin-bottom: 0px;
  word-break: break-all;
}

/* -----yourreportDetails----- */
.scrollTopYourReport {
  max-height: 108vh;
  overflow-y: auto;
  margin-bottom: 15px;
}
.scrollTopYourReport::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.scrollTopYourReport::-webkit-scrollbar-track {
  background-color: white;
}

/* Handle */
.scrollTopYourReport::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

.reportImg {
  width: 100%;
  max-width: 63.23px;
  border-radius: 100%;
  margin-right: 15px;
}
.reportImg img {
  width: 100%;
  max-width: 63.23px;
  cursor: pointer;
  border-radius: 100%;
  object-fit: cover;
}

.reportSectionLeft {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 10px 10px;
  border-radius: 5px;
}

.reportSectionLeft:hover {
  background-color: #f5f5f5;
}
.reportText .reportShow {
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.reportText .reportShow p {
  margin-right: 20px;
  margin-bottom: 0px;
  color: #141414;
  font-size: 11.44px;
  font-weight: 400;
  line-height: 13.73px;
  letter-spacing: -0.12319999933242798px;
}

.reportText span {
  margin-right: 20px;
  margin-bottom: 0px;
  color: #e90007;
  font-size: 13px;
  font-weight: 400;
  line-height: 13.73px;
  letter-spacing: -0.12319999933242798px;
}
.reportText .reportShow img {
  margin-bottom: 2px;
}

.borderBottom {
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.searchBar {
  background-color: #fff;
}

.searchReport {
  display: flex;
  justify-content: space-between;
}

.searchBtn button {
  height: 38px;
  background: linear-gradient(90deg, #f40000 0, #b80f0f);
  border-radius: 4px;
  border: 1px solid #d30000 !important;
  color: #fff;
  width: 94px;
}

.followingBtnProfile {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  margin-top: 5px;
}
.emailShow p {
  color: #000000;
  font-size: 15px;
  font-weight: 400;
  margin-top: 10px;
  text-align: center;
}
.emailShow h5 {
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}
.emailShow h4 {
  color: #000000;
  font-size: 21px;
  font-weight: 700;
  text-align: center;
  margin-top: 3px;
  word-break: break-all;
}

.textSection h4 {
  color: #000000;
  font-size: 24px;
  font-weight: 500;
  line-height: 27px;
  margin-bottom: 12px;
  text-align: center;
  letter-spacing: -0.3;
  word-break: break-word;
}

.profileReport {
  background: #000000;
  color: #fff !important;
  border-radius: 4px;
  padding: 7px 10px;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.inputSelect {
  color: #ffffff !important;
  border: unset !important;
  background: transparent;
}
.inputSelect option {
  color: black;
}
.inputSelect:focus-visible {
  outline: unset !important;
}

.searchBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.searchBox button {
  box-shadow: 2px 2px 10px 0px #0000002a;
  background: #ed0107;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #ed0107;
  margin-left: 5px;
}
.searchBox button:hover {
  background: #ed0107 !important;
  border-color: transparent !important;
  color: #ffffff;
}
.linksAdd {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 12px;
}
.linksAdd svg {
  background: #474747;
  color: #ffffff;
  width: 37px;
  height: 39px;
  border-radius: 3px;
  padding: 5px;
}
.twitterLinks {
  padding: 10px !important;
}

.picShow {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.picShow img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

@media only screen and (max-width: 767px) {
  .profileReport {
    margin-top: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 805px) {
  .dashboardInfoBtn .followButton {
    font-size: 12px;
    padding: 10px 15px;
  }
  .dashboardInfoBtn .unfollowButton {
    font-size: 12px;
    padding: 10px 15px;
  }
}

@media only screen and (min-width: 310px) and (max-width: 345px) {
  .dashboardInfoBtn .followButton {
    font-size: 12px;
    padding: 10px 15px;
  }
  .dashboardInfoBtn .unfollowButton {
    font-size: 12px;
    padding: 10px 15px;
  }
}
.reactPlayerAdd {
  width: 300px !important;
  height: 200px !important;
}

@media only screen and (max-width: 420px) {
  .reactPlayerAdd {
    width: 100% !important;
    height: 100% !important;
  }
  .picShow img {
    width: 100%;
    height: 160px;
    object-fit: cover;
  }

  .reportSvg svg {
    margin-left: 15px;
  }
}

.reportTextss p {
  font-size: 17px !important;
  font-weight: 400 !important;
  letter-spacing: -0.1px;
}
.reportTextss span {
  font-size: 17px !important;
  letter-spacing: -0.1px;
}
.reportTextss strong {
  font-size: 17px !important;
  font-weight: 700 !important;
}
.reportTextss .reportShowss p {
  margin-right: 20px;
  margin-bottom: 0px;
  color: #141414;
  font-size: 14.87px !important;
  font-weight: 400 !important;
  line-height: 13.73px;
  letter-spacing: -0.16 !important;
}
.reportSection {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.reportSvg svg {
  color: #657786;
}
.searchInput::placeholder {
  color: #999999 !important;
  font-size: 16px;
  font-weight: 400;
}

.dashboardInfoBtn .followButton {
  background: linear-gradient(169.09deg, #f40000 -9.18%, #7d0e0e 337.8%);
  text-transform: uppercase;
  border: none;
  border-radius: 17px;
  width: 144px;
  height: 37px;
  margin-right: 15px;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 700;
  line-height: 17.07px;
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.dashboardInfoBtn .followButton::after {
  content: "UNFOLLOW";
  position: absolute;
  border: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.dashboardInfoBtn .followButton:hover {
  background: linear-gradient(169.09deg, #f40000 -9.18%, #7d0e0e 337.8%);
}

.dashboardInfoBtn .followButton:hover::after {
  opacity: 1;
}

.dashboardInfoBtn .unfollowButton {
  background-color: #000000;
  text-transform: uppercase;
  border-radius: 17px;
  border: none;
  width: 144px;
  height: 37px;
  margin-right: 15px;
  color: white;
  font-size: 14px;
  font-weight: 700;
  line-height: 17.07px;
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

/* .dashboardInfoBtn .unfollowButton::after {
  content: "FOLLOW";
  position: absolute;
  border: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(169.09deg, #f40000 -9.18%, #7d0e0e 337.8%);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
} */

.dashboardInfoBtn .unfollowButton:hover {
  background-color: #000000;
}

.dashboardInfoBtn .unfollowButton:hover::after {
  opacity: 1;
}

.greenIconOnlineMain {
  position: absolute;
  bottom: 10%;
  right: 0%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: #00a703;
  border: 2px solid #f3f5f3;
}

.sendMessageBtn {
  background: linear-gradient(169.09deg, #f40000 -9.18%, #7d0e0e 337.8%);
  text-transform: uppercase;
  border: none;
  border-radius: 17px;
  width: 144px;
  height: 37px;
  margin-right: 15px;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 700;
  line-height: 17.07px;
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}
