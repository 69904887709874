.dashboardInfo {
  width: 100%;
  background: #ffffff;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 20px;
}

.dashboardImg {
  width: 100%;
  max-width: 100px;
  border-radius: 100%;
  margin-right: 12px;
  position: relative;
}

.dashboardImg img {
  width: 100%;
  max-width: 100px;
  object-fit: cover;
  border-radius: 100%;
}

.dashboardText p {
  color: #e90202;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  margin-bottom: 3px;
}

.dashboardText h3 {
  color: #000000;
  font-size: 19px;
  font-weight: 500;
  line-height: 23.12px;
  text-align: left;
  margin-bottom: 10px;
  word-break: break-word;
}

.dashboardInfoBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.dashboardText h5 {
  color: #e90202;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.dashboardText h5 strong {
  color: #e90202;
  font-size: 15px;
  font-weight: 700;
}

.followersButtonMainDiv {
  display: flex;
}

.dashboardInfoBtnRemove .followersRemoveBtn {
  border: 2px solid #ff0000;
  font-size: 11.99px;
  font-weight: 700;
  line-height: 14.62px;
  text-align: center;
  border-radius: 20px;
  padding: 7.3px 34px;
  text-transform: uppercase;
  color: #e70202;
  background-color: white;
}

.dashboardInfoBtnRemove .followersRemoveBtn:hover {
  background: linear-gradient(169.09deg, #f40000 -9.18%, #7d0e0e 337.8%);
  color: white;
}

/* ------- */
.priveteIcon {
  position: absolute;
  bottom: 0%;
  right: 0%;
  width: 33px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: linear-gradient(169.09deg, #f40000 -9.18%, #7d0e0e 337.8%);
  border: 1.72px solid #ffffff;
}

.priveteIcon svg {
  color: white;
  font-size: 21px;
}

.priveteIcon img {
  width: 19px;
  height: 19px;
}

.priveteMessage {
  display: flex;
  justify-content: start;
  align-items: start;
  background: #303c42;
  padding: 12px 10px 0px 10px;
  border-radius: 5px;
  color: white;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.priveteMessage h2 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 12px;
}

.priveteMessage h2 p {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.priveteMessage h2 p svg {
  font-size: 20px;
  color: white;
}

.loadingIndicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
  background-color: white;
  margin-top: 1px;
  border-radius: 5px;
}

.loadingIndicator h2 {
  font-size: 20px;
  font-weight: 500;
  color: #373a3c;
}

/* -----timelineScroll--- */
.scrollTopFollowing {
  max-height: 101vh;
  overflow-y: auto;
  margin-bottom: 15px;
  margin-top: 29px;
}

.scrollTopFollowing::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.scrollTopFollowing::-webkit-scrollbar-track {
  background-color: white;
}

/* Handle */
.scrollTopFollowing::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

@media only screen and (max-width: 450px) {
  .dashboardInfo {
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .dashboardImg {
    margin-right: 0px;
    margin-bottom: 10px;
  }

  .dashboardText p {
    text-align: center;
  }

  .dashboardText h3 {
    text-align: center;
  }

  .dashboardInfoBtn .unfollowButton {
    padding: 7.3px 26px;
  }
}

/* following hover effect */
.dashboardInfoBtn .followingButton {
  color: white;
  text-transform: uppercase;
  border-radius: 20px;
  width: 150px;
  margin-right: 12px;
  line-height: 14.62px;
  border: 2px solid #e90202;
  font-size: 11.99px;
  font-weight: 700;
  line-height: 14.62px;
  padding: 7.3px 34px;
  background: linear-gradient(169.09deg, #f40000 -9.18%, #7d0e0e 337.8%);
  transition: background-color 0.3s, border-color 0.3s, content 0.3s;
}

.dashboardInfoBtn .followingButton:hover {
  border-color: #000000;
  background-color: #000000;
  color: white;
  font-size: 11.99px;
  font-weight: 700;
  line-height: 14.62px;
}

.dashboardInfoBtn .followingButton::after {
  content: "Following";
}

.dashboardInfoBtn .followingButton:hover::after {
  content: "Unfollow";
}

.dashboardInfoBtn .unfollowingButton {
  text-transform: uppercase;
  border-radius: 20px;
  padding: 7.3px 34px;
  width: 150px;
  margin-right: 12px;
  color: #e70202;
  font-size: 11.99px;
  font-weight: 700;
  line-height: 14.62px;
  line-height: 14.62px;
  border: 2px solid #e90202;
  background-color: #000000 !important;
  transition: background-color 0.3s, border-color 0.3s, content 0.3s;
}

.dashboardInfoBtn .unfollowingButton:hover {
  border-color: #000000;
  background-color: #000000;
  color: white;
  font-size: 11.99px;
  font-weight: 700;
  line-height: 14.62px;
}

.dashboardInfoBtn .unfollowingButton::after {
  content: "Following";
}

.dashboardInfoBtn .unfollowingButton:hover::after {
  content: "Unfollowing";
  background-color: #000000;
}

/* followers hover effect */

.dashboardInfoBtn .followButton {
  background: linear-gradient(169.09deg, #f40000 -9.18%, #7d0e0e 337.8%);
  text-transform: uppercase;
  border: none;
  border-radius: 17px;
  width: 123px;
  height: 34px;
  margin-right: 12px;
  color: white;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

/* .dashboardInfoBtn .followButton:hover {
  background: linear-gradient(169.09deg, #f40000 -9.18%, #7d0e0e 337.8%);
}*/

.dashboardInfoBtn .followButton::after {
  content: "UNFOLLOW";
  position: absolute;
  border: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.dashboardInfoBtn .followButton:hover::after {
  opacity: 1;
}

.dashboardInfoBtn .unfollowButton {
  background-color: #000000;
  text-transform: uppercase;
  border-radius: 17px;
  border: none;
  width: 123px;
  height: 34px;
  margin-right: 12px;
  color: white;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;

  position: relative;
  overflow: hidden;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}
/* 
.dashboardInfoBtn .unfollowButton:hover {
  background-color: #000000;
}

.dashboardInfoBtn .unfollowButton::after {
  content: "FOLLOW";
  position: absolute;
  border: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(169.09deg, #f40000 -9.18%, #7d0e0e 337.8%);

  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.dashboardInfoBtn .unfollowButton:hover::after {
  opacity: 1;
} */

@media (max-width: 449px) {
  .followersButtonMainDiv {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
