.headerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headerContent h4 {
  color: #fff;
  font-size: 22px;
  margin-bottom: 0px;
}
.headerContent svg {
  color: #fff;
  cursor: pointer;
}
.paraCondition {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.termsCondition {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  padding: 0px 25px;
}
.termsFooter button {
  color: #4c4c4c !important;
  border: 2px solid rgba(0, 0, 0, 0.7) !important;
  height: 48px;
  text-transform: uppercase;
  border-radius: 4px;
  padding: 2px 55px;
  font-size: 20px;
  font-weight: 700;
  background: #ffffff !important;
}
.termsFooter button:active {
  color: #000000 !important;
  background-color: #ffffff !important;
  border-color: 1px solid #dcdcdc;
}
.termsFooter button:hover {
  background: #ffffff !important;
  border: 1px solid #dcdcdc;
  color: #000000 !important;
}

.termsHeading {
  padding: 0px 25px;
}

.termsHeading p {
  color: red;
  font-size: 18px;
}

.termsConditionMainDiv {
  background-color: white;
  padding: 20px 0px;
}

.termsConditionButtion {
  display: flex;
  justify-content: center;
  align-items: center;
}
.termsConditionButtion button {
  background: linear-gradient(169.09deg, #f40000 -9.18%, #7d0e0e 337.8%);
  font-size: 14px;
  font-weight: 700;
  line-height: 18.29px;
  text-align: center;
  border: none;
  padding: 0px 25px;
  border-radius: 50px;
  color: rgb(230, 229, 229);
  height: 38px;
  margin-bottom: 10px;
  width: 15%;
  margin-top: 5px;
}

.termsConditionButtion button:hover {
  color: white;
}

/* invite-friends */

.inviteInnerText {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
}
.inviteInnerText h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  text-align: center;
  margin-top: 20px;
}

.inviteInnerText h1 {
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
  margin-top: 30px;
}

.inviteInnerText h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
  color: #e90007;
  margin-top: 12px;
}

.inviteDescription {
  margin-top: 10px;
}

.inviteDescription h4 {
  font-size: 19px;
  font-weight: 700;
  text-align: center;
  color: #545454;
  margin-bottom: 0px;
}

.inviteDescription h5 {
  font-size: 19px;
  font-weight: 400;
  text-align: center;
  color: #545454;
}

.inviteInputFild {
  margin-bottom: 40px;
}

/* input */
.inviteForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputField {
  width: 80%;
  max-width: 322px;
  padding: 6px;
  border-radius: 5px;
  margin-bottom: 2px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  border: 1px solid #cccccc;
  outline-offset: 0px;
}

.inviteButton {
  background: linear-gradient(169.09deg, #f40000 -9.18%, #7d0e0e 337.8%);
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  margin-top: 30px;
  width: 70%;
  max-width: 295px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  height: 50px;
}

.inviteButton:hover {
  background: linear-gradient(169.09deg, #f40000 -9.18%, #7d0e0e 337.8%);
}

.inviteForm h3 {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #373a3c;
  margin-top: 15px;
  margin-bottom: 10px;
}

.inputField:focus-visible {
  outline: none;
}

.reportRightAd {
  width: 100%;
  height: 280px;
  margin-bottom: 20px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Lato;
  font-size: 21px;
  font-weight: 700;
  text-align: center;
}
