.profileFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bottomText {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.2px;
  color: #818a91;
  margin-bottom: 0px;
}

.inputGroup {
  border-radius: 5px;
  color: #ffffff;
  height: 38px;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  padding: 0px 9px !important;
}

.inputGroup svg {
  font-size: 23px;
  color: #ffffff;
}

.profileInputs {
  position: relative;
}

.profileInputs .checkIcon {
  position: absolute;
  right: -33px;
  bottom: 5px;
  color: #28a84b;
  width: 28px;
  height: 28px;
}

.requiredAstrick {
  color: red;
  width: 20px;
}
