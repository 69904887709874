.searchDiv {
  position: relative;
}
.searchDiv svg {
  position: absolute;
  top: 9px;
  font-size: 22px;
  left: 6px;
}
.location {
  margin-bottom: 20px;
}
.location input {
  background: #ffffff;
  height: 40px;
  border: 1px solid #dcdcdc;
  color: #000000;
  padding-left: 38px;
  width: 100%;
  font-family: Lato;
  font-size: 15px;
  font-weight: 400;
}
.location input:focus {
  box-shadow: unset !important;
  background: #ffffff;
  border: 1px solid #dcdcdc !important;
}
.location input::placeholder {
  color: #999999 !important;
}

.reportSelect button {
  background: #ffffff;
  border: unset;
  width: 100%;
  color: #000000;
  border: 1px solid #dcdcdc;
  height: 40px;
}
.reportSelect button:active {
  color: #000000 !important;
  background-color: #ffffff !important;
  border-color: 1px solid #dcdcdc;
}
.reportSelect button:hover {
  background: #ffffff !important;
  border: 1px solid #dcdcdc;
  color: #000000 !important;
}
.reportManage {
  display: flex;
  align-items: center;
}
.reportManage p {
  margin-bottom: 0px;
  margin-left: 4px;
}
.reportManage svg {
  font-size: 25px;
}

.mapContent {
  margin-top: 20px;
}
.mapContent h2 {
  font-size: 38px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: -0.8685714602470398px;
  text-align: center;
  color: #dd010c;
  margin-bottom: 30px;
}
.mapContent p {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #000000;
}
.mapContent button {
  display: flex;
  justify-content: center;
  margin: 24px auto;
  box-shadow: 2px 2px 10px 0px #0000002a;
  background: #ed0107;
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
  border-radius: 150px;
  border: 1px solid #ed0107;
  padding: 11px 40px 12px;
}
.mapContent button:hover {
  background: #ed0107 !important;
  border-color: transparent !important;
  color: #ffffff;
}
.mapContent h4 {
  font-size: 23px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  margin-bottom: 1.5rem;
}

@media only screen and (max-width: 991px) {
  .reportSelect {
    margin: 10px 0px;
  }
  .location {
    margin-bottom: 10px;
  }
}

.googleMapSet {
  position: relative !important;
  height: 100% !important;
  width: 100% !important;
}
.currentLocationButton {
  position: absolute;
  left: 12px;
  bottom: 10px;

  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: black;
  cursor: pointer;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mapShowList {
  display: grid;
  gap: 10px;
  margin-bottom: 30px;
}

.mapShow {
  border-radius: 5px;
  overflow: hidden;
}

.reportDataShow strong {
  color: #d60303 !important;
  font-weight: 700 !important;
}

.reportDataShow {
  font-size: 13.5px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.09px;
  text-align: left;
  color: #000000;
  margin-top: 10px;
}

@media (min-width: 1200px) {
  .mapShowList {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .mapShowList {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 550px) and (max-width: 768px) {
  .mapShowList {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 325px) and (max-width: 550px) {
  .mapShowList {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 325px) {
  .mapShowList {
    grid-template-columns: 1fr;
  }
}
