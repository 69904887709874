.headerButton button {
  font-size: 14px;
  font-weight: 700;
  border-radius: 150px;
  color: #ffffff !important;
  background: #000 !important;
  border: unset;
  margin-left: 15px;
  padding: 8px 20px;
  text-transform: uppercase;
}
.btn:active {
  color: #ffffff !important;
  background-color: #000 !important;
  border-color: unset;
}

.headerButton button:hover {
  background: #000 !important;
  border: unset;
}

@media only screen and (max-width: 991px) {
  .headerButton button {
    padding: 6px 9px;
    margin-left: 13px;
  }
}

.loginBtn button {
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 20px;
  padding: 17px 17px 18px;
  background: linear-gradient(90deg, #f40000 0, #b80f0f);
  color: #ffffff;
  border: unset !important;
  width: 100%;
  border-radius: 150px;
  text-transform: uppercase;
}

.userButton button {
  border: 1px solid #cccccc;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  color: #373a3c;
  height: 52px;
  width: 100%;
  padding: 0px 22px;
  margin: 10px 0px;
  background: #ffffff;
  border-radius: 5px;
}
.userButton button:hover {
  background: #ffffff !important;
  border: 1px solid #cccccc;
  color: #373a3c;
}
.userButton button:active {
  background: #ffffff !important;
  border: 1px solid #cccccc !important;
  color: #373a3c !important;
}
