.notification {
  margin-top: 29px;
  background: #ffffff;
  border-radius: 5px;
}

.notification h3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #000000;
  padding: 20px;
  margin-bottom: 0px;
}

.head h2 {
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  color: #000000;
  padding: 20px;
  margin-bottom: 0px;
}

.crimeNotify {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 2px solid #efefef;
  padding: 15px 20px;
}

.emailPara {
  padding: 10px 20px;
}

.crimeNotify .crimeNotifySpace {
  width: 100%;
}

.crimeNotify h5 {
  font-size: 15px;
  font-weight: 700;
  line-height: 17.9px;
  color: #000000;
  margin-bottom: 5px;
}

.crimeNotify p {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.71px;
  color: #000000;
  margin-bottom: 0px;
}

.crimeNotify p span {
  font-weight: 500;
  color: #d80000;
}

@media only screen and (max-width: 410px) {
  .crimeNotifyDispaly {
    display: block;
  }

  .crimeNotify .crimeNotifySpace {
    max-width: unset;
    margin-right: 12px;
  }
}

.devider {
  width: 90%;
  height: 1px;
  background: rgb(215, 46, 46);
  margin: 5px 16px;
}

.emailAddress {
  display: flex;
  padding: 2px 5px;
}

.emailAddress svg {
  font-size: 22px;
  color: red;
  margin-top: 3px;
}

.emailAddress p {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  margin: 6px;
}

.emailAddress span {
  color: #000;
  font-size: 14px;
  font-weight: 500;
}

.crimeNotifyBtn {
  display: flex;
  background-color: #e1e1e1 !important;
  justify-content: space-between;
  align-items: center;
  border-top: 2px solid #efefef;
  padding: 15px 20px;
}

.valueStyle {
  margin-bottom: 20px;
}

.valueStyle h3 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 0;
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  padding: 0px;
  color: #000000;
}