.connectTwitterMain {
  background-color: white;
  margin-top: 29px;
  border-radius: 5px;
}
.connectTwitteInnerOne {
  padding: 20px;
}
.connectTwitteInnerOne h3 {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.71px;
  color: #000000;
  margin-bottom: 0px;
}

.hrborder {
  border: 2px solid #dad8d8;
  margin-top: 0px;
  margin-bottom: 5px;
  height: 1px;
  width: 100%;
}
.connectTwitteAccount {
  background-color: white;
  padding: 20px;
}

.connectTwitteAccountInner {
  max-width: 320px;
  height: 38px;
  background-color: #1da1f2;
  display: flex;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
}

.connectTwitteAccountInner h3 {
  font-size: 17px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 0px;
  color: white;
}
.twitterSocialIcon {
  width: 35px !important;
  height: 34px !important;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  margin-left: 2px;
  border-radius: 5px;
}

.twitterSocialIcon svg {
  width: 22px;
  height: 18px;
  fill: black;
}
.twitterSocialIcon svg:hover {
  width: 24px;
  height: 20px;
}

.followButtonadd {
  background: linear-gradient(169.09deg, #f40000 -9.18%, #7d0e0e 337.8%);
  text-transform: uppercase;
  border: none;
  border-radius: 20px;
  width: 144px;
  height: 37px;
  margin-right: 15px;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 700;
  line-height: 17.07px;
  margin-top: 15px;
}
