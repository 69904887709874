.modalClass .modalHeader h4 {
  color: #fff !important;
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 0;
  font-weight: 400;
}
.modalHeader {
  background: #870000;
  background: linear-gradient(90deg, #870000 0, #420000);
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  padding: 15px 30px 13px;
}
.modalClass .modalBody {
  padding: 30px;
}
.modalClass .modalBody h5 {
  font-size: 18px;
  color: #000;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  margin-bottom: 13px;
}
.modalClass .modalBody p {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.modalClass .modalBody p a {
  color: #d3010f;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
}
.modalClass .modalBody p a:hover {
  color: #6ac259;
  text-decoration: underline;
}
.modalClass .modalButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px 3px;
}
.modalClass .modalButton .leftButton {
  background: transparent;
  border-color: transparent;
  color: #212529;
  font-size: 14px;
  margin: 0 5px 10px 0;
  min-width: 144px;
  padding: 7px 17px 8px;
  text-transform: uppercase;
  font-weight: 700;
}
.modalBody input {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  font-size: 26px;
  font-weight: 700;
  height: 50px;
  margin-bottom: 5px;
  margin-right: 5px;
  max-width: 320px;
  padding-left: 15px;
  width: 100%;
}
.modalBody input::placeholder {
  font-size: 14px;
  color: #999999 !important;
}
.modalBody input:focus {
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: unset !important;
}
@media only screen and (max-width: 375px) {
  .modalClass .modalButton {
    justify-content: center;
  }
}
.modalClass.modal-dialog {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  min-height: calc(100vh - 20px) !important;
}

.modalClass.modal-content {
  width: 100% !important;
}
