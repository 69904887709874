.dashboardInfo {
  background: #ffffff;
  padding: 15px;
  margin-top: 29px;
  display: flex;
  align-items: center;
  border-radius: 5px;
}
.dashboardImg {
  width: 100%;
  max-width: 130px;
  border-radius: 100%;
  margin-right: 15px;
}
.dashboardImg img {
  width: 100%;
  max-width: 130px;
  object-fit: cover;
  border-radius: 100%;
}
.dashboardText p {
  color: #e90202;
  font-size: 17px;
  font-weight: 500;
  line-height: 20.29px;
  margin-bottom: 3px;
}
.dashboardText h3 {
  color: #000000;
  font-size: 24px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: -0.20000000298023224px;
  margin-bottom: 12px;
  word-break: break-word;
}
.dashboardText h5 {
  color: #e90202;
  font-size: 17px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
}
.dashboardText h5 strong {
  color: #e90202;
  font-size: 17px;
  font-weight: 700;
  line-height: 18px;
}
.dashboardText h6 {
  color: #d70000;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.dashboardSpacce {
  margin-top: 20px;
  background: #ffffff;
  padding: 19px;
  border: 1px solid #dcdcdc;
}
.dashboardSpacce h2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  color: #373a3c;
  margin-bottom: 0px;
  text-transform: uppercase;
}
.dashboardBoxes {
  background: #ffffff;
  padding: 20px 20px 0px 20px;
  border: 1px solid #dcdcdc;
  border-top: unset;
}
.dashboardBox {
  border: 1px solid #dcdcdc;
  background: #fafafa;
  padding: 10px 4px;
  border-radius: 5px;
  margin-bottom: 20px;
  height: 90px;
}
.dashboardBox:hover {
  background: #db010d;
  color: white !important;
}
.dashboardBox p:hover {
  color: white !important;
}
.dashboardBox h1:hover {
  color: white !important;
}
.dashboardBox h1 {
  font-size: 30px;
  font-weight: 400;
  letter-spacing: -0.2142857164144516px;
  text-align: center;
}
.dashboardBox p {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.10000000149011612px;
  text-align: center;
  margin-bottom: 0px;
  text-transform: uppercase;
}
@media only screen and (max-width: 400px) {
  .dashboardBox h1 {
    font-size: 20px;
  }
  .dashboardBox p {
    font-size: 11px;
  }
  .dashboardImg {
    max-width: 90px;
    margin-right: 10px;
  }
  .dashboardImg img {
    max-width: 90px;
  }
  .dashboardText p {
    font-size: 14px;
    margin-bottom: 0px;
  }
  .dashboardText h3 {
    font-size: 18px;
    line-height: 21px;
  }
  .dashboardText h5 {
    font-size: 15px;
  }
  .dashboardText h5 strong {
    font-size: 15px;
  }
  .dashboardText h6 {
    font-size: 10px;
    line-height: 14px;
  }
}
