.reportIncident {
  background: #ffffff;
  margin-top: 29px;
  padding: 20px 0px;
  border-radius: 5px;
}

.reportIncident h3 {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  color: #000000;
  padding-left: 20px;
  margin-bottom: 16px;
  text-transform: uppercase;
}

.reportIncident h3 strong {
  font-weight: 900;
}

.reportSelect {
  padding: 20px 20px 20px 0px;
  border-bottom: 1px solid #dadada;
  border-top: 1px solid #dadada;
  margin-left: 20px;
  position: relative;
}

.reportSelect svg {
  color: white;
  width: 22px;
  height: 18px;
}

.filterSection {
  display: flex;
  padding: 20px 20px 0px;
  justify-content: space-between;
}

.chekboxSet {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chekboxSet input {
  border: 1px solid #cccccc;
  margin-bottom: 10px;
  cursor: pointer;
}

.chekboxSet label {
  color: #4c4c4c;
  font-size: 15px;
  font-weight: 700;
}

.chekboxSet input:focus {
  box-shadow: unset !important;
  border: 1px solid #cccccc;
}

.filterSection h5 {
  color: #d30000;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 10px;
}

.filterSection .filterInputGroup {
  background: #4c4c4c;
  color: #ffffff;
  height: 38px;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.datepicker input {
  background: #f6f6f6;
  height: 38px;
  border: unset;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  padding-left: 11px;
  width: 100%;
}

.datepicker input:focus-visible {
  outline: unset !important;
}

.location {
  padding: 0px 20px 0px;
}

.location h5 {
  color: #d30000;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.showCenter h5 strong {
  font-weight: 700 !important;
}

.showCenter h6 strong {
  font-weight: 700;
}

.location .inputGroup {
  background: #4c4c4c;
  color: #ffffff;
  height: 38px;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.location input {
  background: #f6f6f6;
  height: 38px;
  border: unset;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  padding-left: 11px;
  width: 100%;
}

.location input:focus {
  box-shadow: unset !important;
  background: #f6f6f6;
}

.location input::placeholder {
  color: #999999 !important;
}

.location p {
  color: #4c4c4c;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.2px;
  margin-bottom: 0px;
}

.googleMapSet {
  margin: 15px -10px 10px;
}

.showCenter {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 15px;
  border-bottom: 1px solid #dadada;
}

.showCenter h6 {
  font-size: 15px;
  font-weight: 700;
  line-height: 19.2px;
  color: #4c4c4c;
  margin-bottom: 0px;
  cursor: pointer;
}

.showCenter p {
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: 400;
  line-height: 19.2px;
  color: #4c4c4c;
}

.showCenter p strong {
  font-size: 15px;
  font-weight: 700;
  line-height: 19.2px;
  margin-left: 10px;
}

.location h5 {
  color: #d30000;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.showCenter h5 strong {
  font-weight: 700 !important;
}

.hashTag {
  color: #6b6b6b !important;
  margin-top: 5px;
}

.showCenter img {
  margin-right: 5px;
  margin-bottom: 4px;
}

/* image upload css */
.uploadSection label {
  background: #000000;
  color: #ffffff;
  width: 100%;
  border-radius: 8px;
  text-align: center;
  padding: 12px 0px;
  font-size: 13px;
  font-weight: 700;
  margin: 15px 0px 0px;
  cursor: pointer;
}

.uploadSection label svg {
  width: 27px;
  height: 21px;
  margin-right: 2px;
  margin-bottom: 4px;
}

.bottomReport {
  background: #ffffff;
  padding: 20px;
  border-radius: 5px;
}

.bottomReport h2 {
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  color: #373a3c;
  text-align: center;
}

.bottomReport h2 strong {
  color: #d80000;
  cursor: pointer;
}

.bottomReport .reportButton {
  display: flex;
  margin-top: 25px;
}

.bottomReport .reportButton .leftButton {
  color: #4c4c4c !important;
  border: 2px solid #4c4c4c !important;
  height: 52px;
  text-transform: uppercase;
  border-radius: 4px;
  padding: 2px 26px;
  font-size: 17px;
  font-weight: 900;
  background: #ffffff !important;
}

.bottomReport .reportButton .rightButton {
  border: 1px solid #d40101 !important;
  background: #d80000 !important;
  color: #ffffff !important;
  height: 52px;
  text-transform: uppercase;
  border-radius: 4px;
  margin-left: 20px;
  width: 100%;
  font-size: 17px;
  font-weight: 900;
}

/* slider div css */
.reportSlider {
  margin-bottom: 10px;
  background-color: white;
}

.reportInfo {
  background: #ffffff;
  padding: 15px 46px;
  display: flex !important;
  align-items: center;
  position: relative;
}

.reportImg {
  width: 100%;
  max-width: 100px;
  border-radius: 100%;
  margin-right: 20px;
}

.reportImg img {
  width: 100%;
  max-width: 100px;
  border-radius: 100%;
}

.reportImgs {
  width: 100%;
  max-width: 100px;
  border-radius: 100%;
  margin-right: 20px;
}

.reportImgs img {
  width: 100%;
  max-width: 100px;
  border-radius: 50%;
}

.reportText p {
  color: #d81010;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
}

.reportText p strong {
  margin-left: 20px;
}

.reportText h3 {
  font-size: 23px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: -0.5px;
  color: #4c4c4c;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  word-break: break-word;
}

.reportText h6 {
  color: #d81010;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
}

.reportSlider button {
  background: #cdcdcd;
  color: #d80000;
}

.arrrowLeft {
  position: absolute !important;
  color: red;
  z-index: 1;
  top: 0%;
  left: 0px;
  display: block;
  background: #cdcdcd;
  height: 100%;
  width: 40px;
}

.arrrowLeft img {
  position: absolute;
  top: 37%;
  left: -11px;
  width: 60px;
  height: 29px;
  cursor: pointer;
}

.arrrowRight {
  position: absolute !important;
  color: red;
  z-index: 1;
  top: 0%;
  right: 0px;
  display: block;
  background: #cdcdcd;
  height: 100%;
  width: 40px;
}

.arrrowRight img {
  position: absolute;
  top: 37%;
  right: -11px;
  width: 60px;
  height: 29px;
  cursor: pointer;
}

@media only screen and (min-width: 768px) and (max-width: 800px) {
  .datepicker input {
    width: 100%;
    max-width: 136px;
  }
}

@media only screen and (min-width: 420px) and (max-width: 533px) {
  .datepicker input {
    width: 100%;
    max-width: 160px;
  }

  .reportText p {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .reportText h3 {
    font-size: 20px;
  }

  .reportImg {
    max-width: 100px;
    margin-right: 10px;
  }

  .reportImg img {
    max-width: 100px;
  }

  .chekboxSet label {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 419px) {
  .filterSection {
    display: block;
  }

  .reportInfo {
    flex-direction: column;
  }

  .reportText p {
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 5px;
    text-align: center;
  }

  .reportText h3 {
    font-size: 19px;
    text-align: center;
  }

  .bottomReport h2 {
    font-size: 17px;
  }

  .bottomReport .reportButton .leftButton {
    font-size: 13px;
  }

  .bottomReport .reportButton .rightButton {
    font-size: 13px;
  }

  .timeboxSet {
    width: 68% !important;
  }
}

.errorDiv {
  margin-top: -15px;
  margin-bottom: 10px;
}

.showImages {
  display: flex;
  flex-wrap: wrap;
}

.showImages .showImageBox {
  width: 106px;
  height: 106px;
  margin-right: 10px;
  margin-top: 10px;
  position: relative;
  background: #000;
  border-radius: 5px;
}

.showImages .showImageBox img {
  width: 106px;
  height: 106px;
  object-fit: cover;
  border-radius: 6px;
}

.reportSelect button {
  background: linear-gradient(90deg, #f40000 0%, #b70f0f 100%);
  border: unset;
  padding: 10px;
  width: 100%;
  color: #ffffff;
}

.reportManage {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reportManage p {
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  border-radius: 5px;
  letter-spacing: -0.5px;
  text-transform: capitalize;
}

/* category css */
.showOptions .categoryBox {
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  height: auto;
}

.categoryBox .mainCategory {
  display: flex;
  align-items: center;
  background: linear-gradient(180deg, #f9f9f9 0, #e1e1e1);
  padding: 4px 7px;
  border: 1px solid #d8d8d8;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.categoryBox .mainCategory img {
  width: 38px;
  height: 43px;
}

.categoryBox .mainCategory h5 {
  font-size: 18px;
  font-weight: 500;
  color: #000;
  font-family: "Lato", sans-serif;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  grid-auto-rows: minmax(100px, auto);
}

.mainCategory img {
  margin-right: 10px;
}

.subCategory {
  cursor: pointer;
  transition: background-color 0.3s;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.subCategory:last-child {
  border-bottom: none;
}

.selected .displayOptionss {
  background-color: #f0f0f0 !important;
}

.displayOptionss {
  background: linear-gradient(180deg, #f9f9f9 0, #e1e1e1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px 6px;
  border: 1px solid #d8d8d8;
}

.displayOptionss p {
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0px;
}

.displayOptionss input[type="checkbox"] {
  margin-right: 10px;
}

.displayOptionss p:hover {
  color: #000;
}

.displayOptionAdd p {
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0px;
}

.selected .displayOptionAdd {
  background-color: #f0f0f0 !important;
}

.displayOptionAdd {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px 6px;
  cursor: pointer;
}

.displayOptionssCls {
  justify-content: unset !important;
}

@media (min-width: 768px) and (max-width: 991px) {
  .gridContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .gridContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 534px) {
  .gridContainer {
    grid-template-columns: 1fr;
  }
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalHeader h4 {
  color: #000000;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 0px;
}

.modalHeader svg {
  color: #000000;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.vehicleDiv {
  background: #f6f6f6;
  height: 38px;
  border: unset;
  color: #000000;
  width: 100%;
  padding: 11px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 5px;
}

.vehicleDiv p {
  font-size: 16px;
  font-weight: 400;
  color: #999999;
  margin-bottom: 0px;
}

.vehicleDiv h6 {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 0px;
}

.vehicleDiv svg {
  font-size: 23px;
  margin-top: -4px;
}

.vehiclesAdd {
  border-bottom: 1px solid #e5e5e5;
}

.reportInfoSpace {
  margin-top: 15px;
  margin-bottom: 15px;
}

.personDetails {
  background: #f6f6f6;
  padding: 20px 10px;
  margin-bottom: 10px;
}

.personDetails h3 {
  font-size: 13px;
  letter-spacing: 3px;
  margin-top: -20px;
  text-align: center;
  margin-bottom: 10px;
}

.ethenicDiv {
  background: #f6f6f6;
  border: unset;
  color: #000000;
  width: 100% !important;
  padding: 11px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #cccccc;
  max-width: 413px;
  margin: 0px auto;
}

.ethenicDiv p {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 0px;
  text-transform: uppercase;
}

.ethenicDiv svg {
  font-size: 23px;
  margin-top: 0px;
}

.isFoundCheck {
  background-color: #308530;
  padding: 10px;
  height: 49px;
  margin-top: 15px;
  border-radius: 8px;
  margin-bottom: -10px;
}

.isFoundCheck {
  justify-content: center;
}

.isFoundCheck input {
  margin-top: 10px;
}

.isFoundCheck label {
  color: #ffffff;
  margin-top: 6px;
}

.isFoundCheck input:checked {
  background-color: red !important;
  border-color: red !important;
}

.showImages .showImageBox .imageCrossIcon {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 0%;
  right: 0%;
  background: linear-gradient(156.2deg, #ff0000 35.43%, #b80218 109.87%);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageCrossIcon svg {
  width: 10px;
  height: 10px;
  fill: white !important;
}

.categoryModalButton {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.categoryModalButton button {
  margin-right: 20px;
}

.categoryModalButton .selectButton {
  border: 1px solid #d40101;
  background: #d80000;
  color: #ffffff;
  height: 52px;
  text-transform: uppercase;
  border-radius: 4px;
  margin-left: 20px;
  width: 100%;
  max-width: 350px;
  font-size: 17px;
  font-weight: 700;
}

.categoryModalButton .selectButton:hover {
  border: 1px solid #d40101 !important;
  background: #d80000 !important;
  color: #ffffff !important;
}

.categoryModalButton .categoryButton {
  margin-right: 20px;
}

.timeboxSet {
  width: 42%;
}

.timePickerSvg {
  background: #4c4c4c;
  color: #ffffff;
  height: 38px;
  width: 40px;
  border-radius: 5px;
  padding: 0.375rem 0.75rem;
}

.timePicker {
  background: #f6f6f6;
  height: 38px;
  border: unset;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.timePicker p {
  margin-bottom: 0px;
}

/* timeslider css */
.timeSlider {
  color: #000;
  text-align: center;
  font-family: "Lato", sans-serif;
  font-weight: 400;
}

.timeSlider h3 {
  margin-bottom: 5px;
  padding-right: 10px;
}

.timeSlider h6 {
  font-size: 12px;
  letter-spacing: 15px;
  padding-left: 50px;
  margin-bottom: 0px;
  margin-top: 10px;
}

.unKnownTime {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 10px;
  margin-bottom: 20px;
}

.unKnownTime h5 {
  margin-bottom: 0px;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 16px;
}

.react-datepicker__current-month {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
  font-size: 50px !important;
}

.vidioUpload .showImageBox {
  margin-bottom: 20px;
  position: relative;
  background: #000;
  border-radius: 5px;
}

.vidioUpload .showImageBox video {
  width: 100% !important;
  height: 100% !important;
}

.vidioUpload .showImageBox .imageCrossIcon {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 0%;
  right: 0%;
  background: linear-gradient(156.2deg, #ff0000 35.43%, #b80218 109.87%);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrrowBg {
  background: transparent !important;
}

.arrrowBgLeft {
  position: absolute !important;
  color: red;
  z-index: 1;
  top: 45%;
  left: 0px;
  display: block;
  background: #cdcdcd;
  width: 40px;
}

.arrrowBgLeft img {
  position: absolute;
  top: 37%;
  left: -11px;
  width: 60px;
  height: 29px;
  cursor: pointer;
}

.arrrowBgRight {
  position: absolute !important;
  color: red;
  z-index: 1;
  top: 45%;
  right: 0px;
  display: block;
  background: #cdcdcd;
  width: 40px;
}

.arrrowBgRight img {
  position: absolute;
  top: 37%;
  right: -11px;
  width: 60px;
  height: 29px;
  cursor: pointer;
}

.showImageBox div {
  position: relative;
}

.imageCrossIcons {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 0%;
  right: 0%;
  background: linear-gradient(156.2deg, #ff0000 35.43%, #b80218 109.87%);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10 !important;
}

.imageCrossIcons svg {
  width: 10px;
  height: 10px;
  fill: white !important;
}

.reactPlayer {
  width: 100% !important;
}

.noteReport {
  background: #dddddd;
  padding: 15px 20px;
  border-radius: 10px;
  margin-top: 15px;
}

.noteReport strong {
  font-weight: 700;
  color: black;
  font-size: 16px;
}

.noteReport p {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 400;
}

.showCenter input {
  background: transparent;
  height: 30px;
  border: unset;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  margin-left: 2px;
  width: 100%;
  max-width: 100px;
  border-radius: 5px;
}

.showCenter input:focus {
  background: rgba(0, 0, 0, 0.13) !important;
  border-color: rgba(0, 0, 0, 0.02) !important;
  box-shadow: unset !important;
}

.showCenter input:focus-visible {
  outline: unset !important;
}

@media only screen and (min-width: 400px) and (max-width: 767px) {
  .showCenter {
    flex-direction: column;
    align-items: unset;
  }

  .showCenter p strong {
    margin-left: 0px;
  }

  .showCenter input {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 399px) {
  .showCenter {
    flex-direction: column;
    align-items: unset;
  }

  .showCenter p strong {
    margin-left: 0px;
  }

  .showCenter input {
    margin-right: 10px;
  }
}

.tabNavlink {
  margin-bottom: 10px;
  color: #000 !important;
  font-weight: 500 !important;
  display: flex !important;
}

.tabNavlink svg {
  font-size: 20px;
  font-weight: 700;
  margin-right: -10px;
  height: 22px;
  width: 22px;
  margin-top: 10px;
  min-width: 22px;
}

@media only screen and (min-width: 992px) {
  .tabContent {
    display: none !important;
  }
}

@media only screen and (max-width: 991px) {
  .categoryResponse {
    display: none !important;
  }

  .tabContent {
    padding: 0px 20px 0px 0px;
  }

  .showVehicle {
    overflow: scroll;
    height: 465px;
    overflow-x: hidden;
    margin-bottom: 15px;
  }
}

.tabReportContent {
  background: #e5e5e5 !important;
  color: #000 !important;
  padding: 20px;
}

.tabReport {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #000 !important;
}

.tabText {
  display: flex;
  justify-content: space-between;
}

@media (min-width: 420px) and (max-width: 534px) {
  .tabContent {
    padding: 0px 0px 0px 0px !important;
  }

  .tabNavlink {
    padding: 5px !important;
  }

  .tabNavlink img {
    width: 35px;
  }

  .tabText {
    font-size: 13px !important;
  }

  .tabReport p {
    font-size: 13px;
  }

  .tabNavlink svg {
    margin-right: 0px;
  }
}

@media (min-width: 370px) and (max-width: 419px) {
  .tabContent {
    padding: 0px 0px 0px 0px !important;
  }

  .tabNavlink {
    padding: 5px !important;
  }

  .tabNavlink img {
    width: 35px;
  }

  .tabText {
    font-size: 13px !important;
  }

  .tabReport p {
    font-size: 13px;
  }

  .tabNavlink svg {
    margin-right: 0px;
    height: 15px;
    width: 15px;
  }
}

@media (min-width: 320px) and (max-width: 369px) {
  .tabNavlink {
    padding: 0px !important;
  }

  .tabReport p {
    font-size: 10px;
  }

  .tabContent {
    padding: 0px 0px 0px 0px !important;
  }

  .tabNavlink img {
    width: 30px;
  }

  .tabNavlink svg {
    margin-right: 0px;
    height: 15px;
    width: 15px;
  }

  .tabText {
    font-size: 11px !important;
  }
}

@media (min-width: 320px) and (max-width: 991px) {
  .categoryModalButton .selectButton {
    width: 100%;
    margin-left: 0px !important;
    max-width: unset !important;
    margin-top: 5px !important;
  }

  .categoryModalButton {
    display: flex;
    align-items: unset !important;
    flex-direction: column !important;
  }

  .categoryModalButton button {
    margin-right: 0px !important;
  }

  .categoryModalButton .categoryButton {
    margin-right: 0px !important;
  }
}

.suggestionsList {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 150px;
  overflow-y: auto;
}

.suggestionItem {
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
}

.suggestionItem:hover {
  background-color: #fb3f3f !important;
  color: #fff;
}

.highlight {
  color: red;
}

.reportMargin {
  background: #ffffff;
  margin-top: 0px !important;
  padding: 20px 0px;
  border-radius: 5px;
}

.reportMargin h3 {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  color: #000000;
  padding-left: 20px;
  margin-bottom: 16px;
  text-transform: uppercase;
}

.reportMargin h3 strong {
  font-weight: 900;
}

.detailsText:focus-visible {
  border: 1px solid transparent !important;
}

.vidioUpload {
  margin-top: 15px;
}

.showVehicle {
  overflow: scroll;
  height: 343px;
  overflow-x: hidden;
  margin-bottom: 15px;
}

.ethenicDivScroll {
  overflow: auto;
  height: 340px;
  overflow-x: hidden;
  margin-bottom: 15px;
}