.LoginPage {
  text-align: center;
  padding-top: 18px;
}
.LoginPage h1 {
  font-size: 33px;
  line-height: 40px;
  color: #000000;
  font-weight: 700;
  margin-bottom: 13px;
  letter-spacing: -0.30000001192092896px;
}
.LoginPage span {
  color: #f70103;
}
.LoginPage h2 {
  color: #e90007;
  font-size: 21px;
  line-height: 26px;
  margin-bottom: 30px;
  font-weight: 700;
  letter-spacing: -0.30000001192092896px;
}
.formBlock {
  width: 100%;
  max-width: 300px;
  margin: 0px auto;
}
.formBlock h5 {
  font-size: 15px;
  letter-spacing: 7px;
  margin: 33px 0 30px;
  text-align: center;
  text-transform: uppercase;
  color: #989898;
  font-weight: 600;
}
.socialImages {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  gap: 12px;
}
.formBlock h4 {
  color: #878787;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 30px;
  margin: 0 0 20px;
  position: relative;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
}
.formBlock h4::before {
  background: #878787;
  content: "";
  height: 3px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  width: 23px;
  left: 103px;
}
.formBlock h4::after {
  background: #878787;
  content: "";
  height: 3px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  width: 23px;
  left: auto;
  right: 103px;
}
.SignupText {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.SignupText span {
  color: #878787;
  padding-left: 8px;
  padding-right: 8px;
}
.SignupText .border {
  border-left: 1px solid #878787;
  padding: 0px !important;
}
.SignupText p {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 0px;
  color: #878787;
  font-style: italic;
}
.SignupTextCode {
  font-size: 13px;
  font-weight: 400;
  line-height: 15.51px;
  text-align: center;
  color: #000000;
}
.SignupText span svg {
  font-size: 20px;
  margin-right: 6px;
  margin-bottom: 4px;
}
.SignupText .EmailText {
  color: #878787;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
}
.SignupText .TextActive {
  color: #f50000 !important;
}
.bottomText {
  text-align: center;
}
.bottomText a {
  color: #d0021b;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
}
.bottomText p {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #000000;
}
.JoinCls {
  display: flex;
}
.JoinBorder {
  border-left: 1px solid black;
  margin-top: 165px;
  margin-bottom: 70px;
}
.mainjoin {
  min-height: calc(100vh - 189px);
  overflow-x: hidden;
  background: #efefef;
}
.leftSpaceManage {
  padding: 0px 40px;
}
.rightSpaceManage {
  padding: 0px 40px;
}
@media only screen and (max-width: 991px) {
  .JoinCls {
    display: flex;
    flex-direction: column;
  }
  .JoinBorder {
    display: none;
  }
  .rightSpaceManage {
    margin-top: -87px;
    padding: 0px;
  }
  .leftSpaceManage {
    padding: 0px;
  }
  .LoginPage {
    padding-top: 0px;
  }
  .passwordSet {
    padding-top: 0px;
  }
}
.passwordSet {
  text-align: center;
  padding-top: 18px;
}
.passwordSet h3 {
  color: #000;
  margin-bottom: 13px;
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.30000001192092896px;
}
.passwordSet h4 {
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.30000001192092896px;
  color: #e90007;
  margin-bottom: 30px;
}
.passworText p {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #000000;
}
.passworText {
  text-align: center;
}
.passworText a {
  color: #d0021b;
  text-decoration: none;
}

.socialDiv {
  width: 85px;
  height: 60px;
  border-radius: 10px;
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.socialDiv svg {
  fill: white;
  width: 19px;
  height: 19px;
}
