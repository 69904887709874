.clearAlert {
  color: #373a3c;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 8px 20px;
  cursor: pointer;
}
.alertBoxes {
  margin-top: 29px;
}
.alertBoxes .alertDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fcf8e3;
}
.alertBoxes .alertDiv p {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.10000000149011612px;
  color: #000000;
  margin-right: 20px;
}
.alertBoxes .alertDiv p strong {
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: -0.10000000149011612px;
  color: #000000;
}
.alertBoxes .alertDiv p span {
  color: #db0429;
}
.alertBoxes .alertDiv img {
  cursor: pointer;
}
/* -----alertLocationScroll--- */
.scrollTopAlertLocation {
  max-height: 90vh;
  overflow-y: auto;
}
.scrollTopAlertLocation::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.scrollTopAlertLocation::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Handle */
.scrollTopAlertLocation::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}
